@import url(https://fonts.googleapis.com/css?family=Open+Sans:400italic,700italic,300,400,700);

/* You can add global styles to this file, and also import other style files */
/* #GOOGLE FONT */
.cdk-overlay-container,.cdk-global-overlay-wrapper{pointer-events:none;top:0;left:0;height:100%;width:100%}
.cdk-overlay-container{position:fixed;z-index:1000}
.cdk-overlay-container:empty{display:none}
.cdk-global-overlay-wrapper{display:flex;position:absolute;z-index:1000}
.cdk-overlay-pane{position:absolute;pointer-events:auto;box-sizing:border-box;z-index:1000;display:flex;max-width:100%;max-height:100%}
.cdk-overlay-backdrop{position:absolute;top:0;bottom:0;left:0;right:0;z-index:1000;pointer-events:auto;-webkit-tap-highlight-color:transparent;transition:opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);opacity:0}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing{opacity:1}
@media screen and (-ms-high-contrast: active){.cdk-overlay-backdrop.cdk-overlay-backdrop-showing{opacity:0.6}}
.cdk-overlay-dark-backdrop{background:rgba(0,0,0,0.288)}
.cdk-overlay-transparent-backdrop,.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing{opacity:0}
.cdk-overlay-connected-position-bounding-box{position:absolute;z-index:1000;display:flex;flex-direction:column;min-width:1px;min-height:1px}
.cdk-global-scrollblock{position:fixed;width:100%;overflow-y:scroll}
.owl-dialog-container{position:relative;pointer-events:auto;box-sizing:border-box;display:block;padding:1.5em;box-shadow:0 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12);border-radius:2px;overflow:auto;background:#fff;color:rgba(0,0,0,0.87);width:100%;height:100%;outline:none}
.owl-dt-container,.owl-dt-container *{box-sizing:border-box}
.owl-dt-container{display:block;font-size:1rem;background:#ffffff;pointer-events:auto;z-index:1000}
.owl-dt-container-row{border-bottom:1px solid rgba(0,0,0,0.12)}
.owl-dt-container-row:last-child{border-bottom:none}
.owl-dt-calendar{display:flex;flex-direction:column;width:100%}
.owl-dt-calendar-control{display:flex;align-items:center;font-size:1em;width:100%;padding:.5em;color:#000000}
.owl-dt-calendar-control .owl-dt-calendar-control-content{flex:1 1 auto;display:flex;justify-content:center;align-items:center}
.owl-dt-calendar-control .owl-dt-calendar-control-content .owl-dt-calendar-control-button{padding:0 .8em}
.owl-dt-calendar-control .owl-dt-calendar-control-content .owl-dt-calendar-control-button:hover{background-color:rgba(0,0,0,0.12)}
.owl-dt-calendar-main{display:flex;flex-direction:column;flex:1 1 auto;padding:0 .5em .5em;outline:0}
.owl-dt-calendar-view{display:block;flex:1 1 auto}
.owl-dt-calendar-multi-year-view{display:flex;align-items:center}
.owl-dt-calendar-multi-year-view .owl-dt-calendar-table{width:calc(100% - 3em)}
.owl-dt-calendar-multi-year-view .owl-dt-calendar-table .owl-dt-calendar-header th{padding-bottom:.25em}
.owl-dt-calendar-table{width:100%;border-collapse:collapse;border-spacing:0}
.owl-dt-calendar-table .owl-dt-calendar-header{color:rgba(0,0,0,0.4)}
.owl-dt-calendar-table .owl-dt-calendar-header .owl-dt-weekdays th{font-size:.7em;font-weight:400;text-align:center;padding-bottom:1em}
.owl-dt-calendar-table .owl-dt-calendar-header .owl-dt-calendar-table-divider{position:relative;height:1px;padding-bottom:.5em}
.owl-dt-calendar-table .owl-dt-calendar-header .owl-dt-calendar-table-divider:after{content:'';position:absolute;top:0;left:-.5em;right:-.5em;height:1px;background:rgba(0,0,0,0.12)}
.owl-dt-calendar-table .owl-dt-calendar-cell{position:relative;height:0;line-height:0;text-align:center;outline:0;color:rgba(0,0,0,0.85);-webkit-appearance:none;-webkit-tap-highlight-color:rgba(0,0,0,0);-webkit-tap-highlight-color:transparent}
.owl-dt-calendar-table .owl-dt-calendar-cell-content{position:absolute;top:5%;left:5%;display:flex;align-items:center;justify-content:center;box-sizing:border-box;width:90%;height:90%;font-size:.8em;line-height:1;border:1px solid transparent;border-radius:999px;color:inherit;cursor:pointer}
.owl-dt-calendar-table .owl-dt-calendar-cell-out{opacity:.2}
.owl-dt-calendar-table .owl-dt-calendar-cell-today:not(.owl-dt-calendar-cell-selected){border-color:rgba(0,0,0,0.4)}
.owl-dt-calendar-table .owl-dt-calendar-cell-selected{color:rgba(255,255,255,0.85);background-color:#3f51b5}
.owl-dt-calendar-table .owl-dt-calendar-cell-selected.owl-dt-calendar-cell-today{box-shadow:inset 0 0 0 1px rgba(255,255,255,0.85)}
.owl-dt-calendar-table .owl-dt-calendar-cell-disabled{cursor:default}
.owl-dt-calendar-table .owl-dt-calendar-cell-disabled>.owl-dt-calendar-cell-content:not(.owl-dt-calendar-cell-selected){color:rgba(0,0,0,0.4)}
.owl-dt-calendar-table .owl-dt-calendar-cell-disabled>.owl-dt-calendar-cell-content.owl-dt-calendar-cell-selected{opacity:.4}
.owl-dt-calendar-table .owl-dt-calendar-cell-disabled>.owl-dt-calendar-cell-today:not(.owl-dt-calendar-cell-selected){border-color:rgba(0,0,0,0.2)}
.owl-dt-calendar-table .owl-dt-calendar-cell-active:focus>.owl-dt-calendar-cell-content:not(.owl-dt-calendar-cell-selected),.owl-dt-calendar-table :not(.owl-dt-calendar-cell-disabled):hover>.owl-dt-calendar-cell-content:not(.owl-dt-calendar-cell-selected){background-color:rgba(0,0,0,0.04)}
.owl-dt-calendar-table .owl-dt-calendar-cell-in-range{background:rgba(63,81,181,0.2)}
.owl-dt-calendar-table .owl-dt-calendar-cell-in-range.owl-dt-calendar-cell-range-from{border-top-left-radius:999px;border-bottom-left-radius:999px}
.owl-dt-calendar-table .owl-dt-calendar-cell-in-range.owl-dt-calendar-cell-range-to{border-top-right-radius:999px;border-bottom-right-radius:999px}
.owl-dt-timer{display:flex;justify-content:center;width:100%;height:7em;padding:.5em;outline:none}
.owl-dt-timer-box{position:relative;display:inline-flex;flex-direction:column;align-items:center;width:25%;height:100%}
.owl-dt-timer-content{flex:1 1 auto;display:flex;justify-content:center;align-items:center;width:100%;margin:.2em 0}
.owl-dt-timer-content .owl-dt-timer-input{display:block;width:2em;text-align:center;border:1px solid rgba(0,0,0,0.5);border-radius:3px;outline:medium none;font-size:1.2em;padding:.2em}
.owl-dt-timer-divider{display:inline-block;align-self:flex-end;position:absolute;width:.6em;height:100%;left:-.3em}
.owl-dt-timer-divider:before,.owl-dt-timer-divider:after{content:'';display:inline-block;width:.35em;height:.35em;position:absolute;left:50%;border-radius:50%;transform:translateX(-50%);background-color:currentColor}
.owl-dt-timer-divider:before{top:35%}
.owl-dt-timer-divider:after{bottom:35%}
.owl-dt-control-button{-webkit-user-select:none;-moz-user-select:none;user-select:none;cursor:pointer;outline:none;border:none;-webkit-tap-highlight-color:transparent;display:inline-block;white-space:nowrap;text-decoration:none;vertical-align:baseline;margin:0;padding:0;background-color:transparent;font-size:1em;color:inherit}
.owl-dt-control-button .owl-dt-control-button-content{position:relative;display:inline-flex;justify-content:center;align-items:center;outline:none}
.owl-dt-control-period-button .owl-dt-control-button-content{height:1.5em;padding:0 .5em;border-radius:3px;transition:background-color 100ms linear}
.owl-dt-control-period-button:hover>.owl-dt-control-button-content{background-color:rgba(0,0,0,0.12)}
.owl-dt-control-period-button .owl-dt-control-button-arrow{display:flex;justify-content:center;align-items:center;width:1em;height:1em;margin:.1em;transition:transform 200ms ease}
.owl-dt-control-arrow-button .owl-dt-control-button-content{padding:0;border-radius:50%;width:1.5em;height:1.5em}
.owl-dt-control-arrow-button[disabled]{color:rgba(0,0,0,0.4);cursor:default}
.owl-dt-control-arrow-button svg{width:50%;height:50%;fill:currentColor}
.owl-dt-inline-container,.owl-dt-popup-container{position:relative;width:18.5em;box-shadow:0 5px 5px -3px rgba(0,0,0,0.2),0 8px 10px 1px rgba(0,0,0,0.14),0 3px 14px 2px rgba(0,0,0,0.12)}
.owl-dt-inline-container .owl-dt-calendar,.owl-dt-inline-container .owl-dt-timer,.owl-dt-popup-container .owl-dt-calendar,.owl-dt-popup-container .owl-dt-timer{width:100%}
.owl-dt-inline-container .owl-dt-calendar,.owl-dt-popup-container .owl-dt-calendar{height:20.25em}
.owl-dt-dialog-container{max-height:95vh;margin:-1.5em}
.owl-dt-dialog-container .owl-dt-calendar{min-width:250px;min-height:330px;max-width:750px;max-height:750px}
.owl-dt-dialog-container .owl-dt-timer{min-width:250px;max-width:750px}
@media all and (orientation: landscape){.owl-dt-dialog-container .owl-dt-calendar{width:58vh;height:62vh}.owl-dt-dialog-container .owl-dt-timer{width:58vh}}
@media all and (orientation: portrait){.owl-dt-dialog-container .owl-dt-calendar{width:80vw;height:80vw}.owl-dt-dialog-container .owl-dt-timer{width:80vw}}
.owl-dt-container-buttons{display:flex;width:100%;height:2em;color:#3f51b5}
.owl-dt-container-control-button{font-size:1em;width:50%;height:100%;border-radius:0}
.owl-dt-container-control-button .owl-dt-control-button-content{height:100%;width:100%;transition:background-color 100ms linear}
.owl-dt-container-control-button:hover .owl-dt-control-button-content{background-color:rgba(0,0,0,0.1)}
.owl-dt-container-info{padding:0 .5em;cursor:pointer;-webkit-tap-highlight-color:transparent}
.owl-dt-container-info .owl-dt-container-range{outline:none}
.owl-dt-container-info .owl-dt-container-range .owl-dt-container-range-content{display:flex;justify-content:space-between;padding:.5em 0;font-size:.8em}
.owl-dt-container-info .owl-dt-container-range:last-child{border-top:1px solid rgba(0,0,0,0.12)}
.owl-dt-container-info .owl-dt-container-info-active{color:#3f51b5}
.owl-dt-container-disabled,.owl-dt-trigger-disabled{opacity:0.35;filter:Alpha(Opacity=35);background-image:none;cursor:default !important}
.owl-dt-timer-hour12{display:flex;justify-content:center;align-items:center;color:#3f51b5}
.owl-dt-timer-hour12 .owl-dt-timer-hour12-box{border:1px solid currentColor;border-radius:2px;transition:background 200ms ease}
.owl-dt-timer-hour12 .owl-dt-timer-hour12-box .owl-dt-control-button-content{width:100%;height:100%;padding:.5em}
.owl-dt-timer-hour12 .owl-dt-timer-hour12-box:focus .owl-dt-control-button-content,.owl-dt-timer-hour12 .owl-dt-timer-hour12-box:hover .owl-dt-control-button-content{background:#3f51b5;color:#ffffff}
.owl-dt-calendar-only-current-month .owl-dt-calendar-cell-out{visibility:hidden;cursor:default}
.owl-dt-inline{display:inline-block}
.owl-dt-control{outline:none;cursor:pointer}
.owl-dt-control .owl-dt-control-content{outline:none}
.owl-dt-control:focus>.owl-dt-control-content{background-color:rgba(0,0,0,0.12)}
.owl-dt-control:not(:-moz-focusring):focus>.owl-dt-control-content{box-shadow:none}
.owl-hidden-accessible{border:0;clip:rect(0 0 0 0);height:1px;margin:-1px;overflow:hidden;padding:0;position:absolute;width:1px}
:root {
  --theme-color-1: #8b9635;
  --header-color:rgba(183, 235, 0, 0.4);
  --hovertext-color: #ffffff;
  --text-color: #111;
  --filter: brightness(0) saturate(100%) ;
}
body:not(.menu-on-top).desktop-detected {
  min-height: auto !important;
  overflow: hidden;
}
body:not(.menu-on-top).mobile-detected {
  min-height: auto !important;
  overflow: hidden;
}
body {
  background: unset;
  background-color: #202124;
}
#content {
  padding: 20px !important;
  max-height: calc(100vh - 100px);
  overflow-y: auto;
}
.page-footer {
  /* padding-left: 0px; */
  z-index: 0;
  border-top: none;
}
#logo {
  background-repeat: no-repeat;
  background-size: 100%;
  height: 49px;
  width: 135px;
  display: block;
  margin-top: 13px;
  margin-left: 36px;
}
.xatena #logo {
  margin-top: 6px !important;
}
.setnavBackgroundImg {
  position: relative;
  z-index: 2;
  max-width: none;
  width: 90px;
  height: 90px;
  cursor: pointer;
  background-repeat: no-repeat;
  display: flex;
  margin: 0 auto;
  width: 110px;
  height: 110px;
}
.setBackgroundCompanyImg {
  position: relative;
  z-index: 2;
  max-width: none;
  width: 200px;
  height: 200px;
  cursor: pointer;
  background-repeat: no-repeat;
  display: flex;
  margin: 0 auto;
}
.setBackgroundCompanylogoImg {
  position: relative;
  z-index: 2;
  max-width: none;
  width: 200px;
  height: 200px;
  cursor: pointer;
  background-repeat: no-repeat;
  display: flex;
}
.col-xs-10 {
  width: 83.33333333% !important;
}
.col-xs-2 {
  width: 16.66666667% !important;
}
.col-xs-3 {
  width: 25% !important;
}
.col-xs-7 {
  width: 58.33333333% !important;
}
.col-xs-8 {
  width: 66.66666667% !important;
}
.col-xs-6 {
  width: 50% !important;
}
.col-xs-1 {
  width: 8.33333333% !important;
}
.col-xs-4 {
  width: 33.33333333% !important;
}
.col-xs-5 {
  width: 41.66666667% !important;
}
#extr-page {
  overflow-x: hidden;
}
#extr-page #header #logo {
  margin-left: 75px !important;
  margin-top: 10px !important;
}
#extr-page #header #logo img {
  width: 200px;
}
.notification-body li span {
  background: #0C1214 !important;
}
.li-footer__copy-text {
  color: #8B91A0;
}
.loader-outer {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px;
}
.loader > span {
  width: 15px;
  height: 15px;
  display: block;
  background: var(--theme-color-1);
  border-radius: 50%;
  position: relative;
  margin: 0 5px;
}
.loader {
  display: flex;
  align-items: center;
  justify-content: center;
}
.dot-1 {
  animation: anim 1s linear 0s infinite;
}
.dot-2 {
  animation: anim 1s linear 0.25s infinite;
}
.dot-3 {
  animation: anim 1s linear 0.5s infinite;
}
.dot-4 {
  animation: anim 1s linear 0.75s infinite;
}
@keyframes anim {
  0% {
    top: 0;
  }
  50% {
    top: 15px;
  }
  100% {
    top: 0;
  }
}
@media (max-width: 1161px) and (min-width: 300px) {
  #extr-page #header #logo img {
    width: 150px;
  }

  #extr-page #header #logo {
    margin-left: 10px !important;
    margin-top: 20px !important;
  }
}
@media only screen and (max-width: 280px) {
  #header > :first-child,
aside {
    width: 150px;
  }

  #extr-page #header #logo img {
    width: 125px;
  }

  #extr-page #header #logo {
    margin-left: 10px !important;
    margin-top: 20px !important;
  }
}
.checkbox i {
  position: absolute;
  top: 3px;
  left: 0;
  display: block;
  width: 17px;
  height: 17px;
  outline: 0;
  border-width: 1px;
  border-style: solid;
  background: transparent !important;
  border: 2px solid #666666;
  border-radius: 5px;
}
.smart-form .checkbox input + i:after {
  color: var(--theme-color-1);
}
.smart-form .checkbox i {
  border-color: #BDBDBD;
  border: 2px solid #666666;
  border-radius: 5px;
}
.smart-form .checkbox:hover i {
  border-color: #666666;
}
.smart-form .checkbox input:checked + i, .smart-form .radio input:checked + i, .smart-form .toggle input:checked + i {
  border-color: #666666;
}
/*
---------------------------------------------------------------------------------------
    Default Dark theme style
---------------------------------------------------------------------------------------
*/
.smart-style-1 {
  /* #logo {
  	background-image: url(/assets/img/iomLogoWhite.svg);
  } */
}
.smart-style-1 #logo-group {
  background: unset !important;
}
.smart-style-1 .btn-header:first-child a {
  background: unset;
  border: 1px solid #e4e4e4;
}
.smart-style-1 #header {
  background-color: #202124;
}
.smart-style-1 aside {
  background-color: #171819;
  border-right: 1px solid #333 !important;
}
.smart-style-1 .page-footer {
  background-color: #000;
}
.smart-style-1 #ribbon {
  background: #707070;
}
.smart-style-1 .ajax-dropdown {
  background-color: #0C1214 !important;
}
.smart-style-1 .ajax-notifications {
  background-color: #0C1214 !important;
}
.smart-style-1 .header-dropdown-list a:hover {
  color: #ffffff !important;
}
.smart-style-1 .setnavBackgroundImg {
  background-image: url(/assets/img/svgImages/Group348.svg);
}
.smart-style-1 .setcompanyImg {
  background-image: url(/assets/img/svgImages/comapnyDefaultDark.svg);
}
.smart-style-1 .setBackgroundCompanyImg,
.smart-style-1 .setBackgroundCompanylogoImg {
  background-image: url(/assets/img/svgImages/comapnyDefaultDark.svg);
}
.smart-style-1 .owl-dt-control-button-arrow {
  fill: #ffffff !important;
}
/*
---------------------------------------------------------------------------------------
    End Dark theme style
---------------------------------------------------------------------------------------
*/
/*
---------------------------------------------------------------------------------------
    Default Dark theme style
---------------------------------------------------------------------------------------
*/
.smart-style-2 .company-label {
  color: #4c4f53 !important;
}
.smart-style-2 #logo {
  background-image: url(/assets/img/iomLogoBlack.svg);
  margin-top: 14px;
}
.smart-style-2 .login-info {
  color: #212529;
}
.smart-style-2 aside {
  color: #ffffff !important;
  background-image: #ededed !important;
}
.smart-style-2 #header {
  background-color: #ffffff !important;
}
.smart-style-2 nav ul .active > a {
  color: #ffffff !important;
  font-weight: 400 !important;
  font-size: 16px !important;
}
.smart-style-2 .setnavBackgroundImg {
  background-image: url(/assets/img/svgImages/Group349.svg);
}
.smart-style-2 .setBackgroundCompanyImg,
.smart-style-2 .setBackgroundCompanylogoImg {
  background-image: url(/assets/img/svgImages/Group350.svg);
}
.smart-style-2 .smart-style-2 .smart-form .checkbox input:checked + i, .smart-style-2 .smart-style-2 .smart-form .radio input:checked + i, .smart-style-2 .smart-style-2 .smart-form .toggle input:checked + i {
  border-color: #666666;
}
/*
---------------------------------------------------------------------------------------
    White Dark theme style
---------------------------------------------------------------------------------------
*/
.smart-style-2.minified #left-panel {
  padding: 0 !important;
  padding-top: 10px !important;
}
@media (max-width: 992px) {
  #content {
    max-height: calc(100vh - 130px) !important;
  }
}
@media (max-width: 576px) {
  #content {
    max-height: calc(100vh - 180px) !important;
  }
}
.cdk-overlay-container {
  z-index: 9999 !important;
}
.owl-dt-inline {
  width: 100% !important;
}
.owl-dt-inline-container,
.owl-dt-popup-container {
  width: 100% !important;
}
.owl-dt-container {
  font-size: 18px !important;
}
.owl-dt-inline-container .owl-dt-calendar,
.owl-dt-popup-container .owl-dt-calendar {
  height: 500px !important;
}
.cdk-overlay-container {
  z-index: 9999 !important;
}
.owl-dt-inline {
  width: 100% !important;
}
.owl-dt-inline-container,
.owl-dt-popup-container {
  width: 100% !important;
}
.owl-dt-container {
  font-size: 18px !important;
}
.owl-dt-inline-container .owl-dt-calendar,
.owl-dt-popup-container .owl-dt-calendar {
  height: 500px !important;
}
.owl-dt-calendar-table .owl-dt-calendar-cell-active:focus > .owl-dt-calendar-cell-content:not(.owl-dt-calendar-cell-selected),
.owl-dt-calendar-table :not(.owl-dt-calendar-cell-disabled):hover > .owl-dt-calendar-cell-content:not(.owl-dt-calendar-cell-selected) {
  background-color: #666666 !important;
  color: #fff !important;
}
.owl-dt-calendar-table .owl-dt-calendar-cell-today:not(.owl-dt-calendar-cell-selected) {
  background-color: #202124 !important;
  border: 1px solid #666666 !important;
  color: #fff !important;
}
@charset "UTF-8";
:root {
  --theme-color-1: #8b9635;
  --header-color:rgba(183, 235, 0, 0.4);
  --hovertext-color: #ffffff;
  --text-color: #111;
  --filter: brightness(0) saturate(100%) ;
}
#ribbon ol.breadcrumb {
  font-size: 13px;
  font-weight: 600;
}
.dt-toolbar {
  display: none !important;
}
table {
  width: 100%;
  background-color: inherit !important;
  color: #ffffff;
  border-collapse: collapse;
}
.sub-btn {
  font-size: 13px;
  border-radius: 15px;
  background-color: transparent !important;
  padding: 3px 15px;
}
.scroll-dashboard {
  overflow-y: auto !important;
  overflow-x: hidden;
  z-index: 1;
  margin-top: 52px;
}
.scroll-dashboard::-webkit-scrollbar-track {
  background-color: #000;
}
.scroll-dashboard::-webkit-scrollbar {
  width: 0px;
}
.scroll-dashboard::-webkit-scrollbar-thumb {
  background-color: #000;
}
.demo {
  position: absolute;
  top: 5px;
  right: 0;
  width: 160px;
  z-index: 902;
  background: #F1DA91;
}
.demo label {
  margin-bottom: 4 !important;
}
.demo label input[type=checkbox].checkbox + span:before {
  border: 1px solid #5E5E5E;
}
.demo label input[type=radio].radiobox + span:before {
  border: 1px solid #5E5E5E;
}
.demo label:hover input[type=checkbox].checkbox + span:before {
  border-color: #E77317;
}
.demo .checkbox {
  padding-left: 22px;
}
.demo .checkbox i {
  border-color: #7A7A7A;
}
.demo .radio {
  padding-left: 22px;
}
.demo #demo-setting + form {
  display: none;
}
.demo span {
  position: absolute;
  right: 0;
  top: 0;
  display: block;
  height: 30px;
  width: 30px;
  border-radius: 5px 0 0 5px;
  background: #F1DA91;
  padding: 2px;
  text-align: center;
  line-height: 28px;
  cursor: pointer;
}
.demo span i {
  font-size: 17px;
}
.demo span:hover i {
  color: #333 !important;
}
.demo form section {
  margin: 0 !important;
}
.demo .btn {
  font-size: 13px !important;
  line-height: 1.5 !important;
  border-radius: 3px !important;
  padding: 3px 7px !important;
  height: auto !important;
}
.demo.activate {
  right: 0 !important;
  box-shadow: -11px 12px 23px rgba(0, 0, 0, 0.2);
  padding: 5px 10px 10px;
}
.demo.activate #demo-setting + form {
  display: block;
}
.demo.activate span {
  left: -30px;
}
.demo.activate span i:before {
  content: "";
}
.demo-liveupdate-1 {
  font-size: 12px !important;
  position: absolute !important;
  left: 33px;
  top: 15px;
  display: block;
  z-index: 2;
  font-weight: 700;
  padding: 1px 61px 3px 7px;
}
.demo-btns {
  margin: 0;
  padding: 0;
  list-style: none;
}
.demo-btns li {
  display: inline-block;
  margin-bottom: 7px;
}
.demo-icon-font {
  font-size: 14px;
  margin-bottom: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
}
.demo-icon-font:hover {
  background-color: rgba(86, 61, 124, 0.1);
  text-decoration: none;
}
.demo-modal {
  width: auto;
  max-width: 600px;
  z-index: 1;
}
.demo-border {
  border: 1px solid rgba(0, 0, 0, 0.2);
}
.demo-vertical-uislider {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}
.demo-vertical-uislider div {
  height: 200px;
  margin: 0 0 0 10px;
  display: inline-block;
}
.demo-vertical-uislider:first-child {
  margin-left: 15px;
}
.toggle-demo {
  position: absolute;
  top: 10px;
  right: -8px;
  display: block;
  width: 154px;
  height: auto;
  z-index: 99;
  opacity: 0.9;
}
.toggle-demo:hover {
  opacity: 1;
}
.toggle-demo .btn-group {
  width: 100px;
  float: left;
}
.toggle-demo span {
  display: inline-block;
  float: left;
  margin-right: 5px;
  line-height: 21px;
  color: #757575;
}
#header .menu-item {
  margin: 0px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 60px;
  cursor: pointer;
}
#header .menu-item_pading {
  padding: 0 10px;
}
#header .menu-left-item {
  padding: 0 10px;
}
#header .company-title {
  font: normal normal bold 12px/19px Open Sans;
}
#header .company-name {
  font: normal normal normal 14px/19px Open Sans;
}
#header #fullscreen .svg-icon {
  background-repeat: no-repeat;
  background-position: center;
  width: 60px;
  height: 45px !important;
  margin-top: -3px;
}
#header .notification-svg {
  background-repeat: no-repeat;
  background-position: center;
  width: 60px !important;
  height: 45px !important;
}
#header #activity.activity-svg {
  background-repeat: no-repeat;
  background-position: center;
  width: 60px !important;
  height: 45px !important;
}
#header .collapseMenu .collaspe-menu-svg {
  background-repeat: no-repeat;
  background-position: center;
  width: 40px !important;
  height: 30px !important;
}
#header .collapseMenu .collaspe-menu-sub-svg {
  background-repeat: no-repeat;
  width: 45px !important;
  height: 45px !important;
}
#header .header-btn:hover {
  border-color: var(--theme-color-1) !important;
  background: var(--theme-color-1) !important;
  color: var(--hovertext-color) !important;
}
#header .dropdown-icon-menu ul li .btn:hover {
  border-color: var(--theme-color-1) !important;
  background: var(--theme-color-1) !important;
  color: var(--hovertext-color) !important;
}
.knobs-demo div {
  display: inline-block;
  margin-left: 30px;
}
.knobs-demo:first-child {
  margin-left: 0;
}
.widget-box {
  background-color: #ffffff;
  box-shadow: 10px 10px 45px 0 rgba(19, 46, 72, 0.2);
  border-radius: 5px;
  overflow: hidden;
  padding: 20px;
}
.widget-box .title-box .title .ed-icon {
  transform: translateY(-50%) scale(0.8);
  transform-origin: left center;
}
.widget-box .title-box .title .title-lbl {
  font-size: 18px;
  line-height: 18px;
  padding-left: 35px;
}
.widget-box .title-box .title .title-lbl:before {
  left: 25px;
}
.widget-box .title-box .title .title-lbl.no-icon {
  padding-left: 0;
}
.widget-box .title-box .title .title-lbl.no-icon:before {
  display: none;
}
.widget-box .icon-box {
  width: 70px;
  min-width: 70px;
  height: 70px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.widget-box .icon-box .number {
  font-size: 30px;
}
.widget-box .icon-box .ed-icon {
  width: 30px;
  height: 30px;
}
.widget-box h3 {
  font-size: 24px;
  line-height: 24px;
}
.widget-box p {
  font-size: 18px;
  line-height: 20px;
}
.activity.ajax-dropdown:first-child {
  margin: 0;
  padding: 0;
}
.activity .ajax-notifications {
  border-bottom: none;
  border-top: none;
}
.applist-box1 .applist-row {
  padding: 0 15px;
  flex-wrap: wrap;
}
.applist-box1 .applist-row .app-box {
  text-align: center;
  padding: 20px 0px;
  font-weight: 500;
}
.applist-box1 .applist-row .app-box .module-icon {
  width: 45px;
  height: 45px;
  background-repeat: no-repeat;
  display: inline-block;
  background-size: 100%;
}
.fixed-navigation .nav-demo-btn {
  display: none !important;
}
.minified .nav-demo-btn {
  display: none !important;
}
.smart-rtl .demo .margin-right-5 {
  margin-right: 0 !important;
}
.listFinal {
  font-size: 16px;
  padding: 5px;
}
.listFinal::before {
  content: ">";
  font-size: 20px;
  padding: 5px;
}
.minified #clearCache_text {
  display: none;
}
.minified #help_text {
  display: none;
}
.minified .userlabel {
  display: none !important;
}
.minified .body-image {
  width: 40px !important;
  height: 40px !important;
  margin: auto;
}
.header-dropdown-list li .dropdown-toggle {
  margin-top: 6px;
}
.dash-icon {
  background-image: url(/assets/img/application/dashboard.svg);
}
.purchase-icon {
  background-image: url(/assets/img/application/purchase.svg);
}
.inv-icon {
  background-image: url(/assets/img/application/invoice.svg);
}
.file-icon {
  background-image: url(/assets/img/application/filemanager.svg);
}
.vmi-icon {
  background-image: url(/assets/img/application/vmi.svg);
}
.contract-icon {
  background-image: url(/assets/img/application/contract.svg);
}
.edi-icon {
  background-image: url(/assets/img/application/edi.svg);
}
.header-dropdown-list .dropdown-menu {
  padding: 4px;
  overflow: auto;
  max-height: 200px;
  border-radius: 10px;
}
.header-dropdown-list .dropdown-menu li a {
  border-radius: 0 !important;
  font-size: 14px;
}
.header-dropdown-list .dropdown-menu::-webkit-scrollbar {
  width: 0px;
}
.nav-demo-btn {
  display: none;
}
body:not(.menu-on-top) .nav-demo-btn {
  display: block;
  padding: 6px 5px;
  margin: 5px 10px;
  width: auto;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  font-size: 12px;
  white-space: normal;
}
.ajax-dropdown-app > :first-child {
  margin: 0 0 3px;
  padding: 0 0 9px;
}
.page-footer {
  position: fixed;
  border-top: transparent;
}
.hidden-menu .page-footer {
  padding-left: 20px;
}
.dashHeadLocation {
  color: #ffffff;
  display: flex;
  list-style: none;
  padding: 0;
}
.listitems {
  font-size: 24px;
}
.main-nav {
  text-align: right;
  font: normal normal normal 16px/22px Open Sans;
  letter-spacing: 0px;
  color: #E2E2E2;
  opacity: 1;
  padding-top: 11px;
}
.main-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-left: 0;
  padding-right: 10px;
}
#content {
  background: #1E1E1E !important;
}
.footerTextColor {
  color: #8B91A0 !important;
}
.theme-header-color {
  color: #E2E2E2 !important;
}
.theme-text-color {
  color: #E2E2E2 !important;
}
.header-menu {
  font: normal normal normal 14px/19px Open Sans;
}
.notification-body > li {
  border: 1px solid #e3e3e3 !important;
}
.flag.flag-en {
  background-position: -144px -154px;
}
#left-panel {
  position: absolute;
  top: 51px;
  z-index: 904;
  padding-top: 10px;
}
#left-panel nav ul li.active > a:before {
  right: -19px;
  content: none !important;
}
.font-size-14 {
  font-size: 14px !important;
}
.notification-body .subject {
  color: #7A7A7A !important;
}
.iombackground {
  background-repeat: no-repeat;
  background-position: center;
  max-height: 100vh;
  height: 100vh;
  background: #C04848;
  /* fallback for old browsers */
  background: linear-gradient(rgba(102, 102, 102, 0.9), rgba(102, 102, 102, 0.9)), url(/assets/img/register.png);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(rgba(102, 102, 102, 0.9), rgba(102, 102, 102, 0.9)), url(/assets/img/register.png);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background-size: cover;
  background-repeat: no-repeat;
}
.userAccountSvg {
  background-repeat: no-repeat;
  background-position: center;
  width: 45px !important;
  height: 45px !important;
  background-image: url('user.23211b4b22ee8a248707.png');
}
.toolsvg {
  background-repeat: no-repeat;
  background-position: center;
  width: 45px !important;
  height: 45px !important;
  background-image: url('tools.9b60c16d7739a9ac429d.png');
}
.support-svg {
  background-repeat: no-repeat;
  background-position: center;
  width: 45px !important;
  height: 45px !important;
  background-image: url('support.f7bfa814958611603cb8.png');
}
.conection-svg {
  background-repeat: no-repeat;
  background-position: center;
  width: 45px !important;
  height: 45px !important;
  background-image: url('conections.7e1bc3b862fe00171b61.png');
}
.data-svg {
  background-repeat: no-repeat;
  background-position: center;
  width: 45px !important;
  height: 45px !important;
  background-image: url('data2x.530f077fc89937c875d0.png');
}
.NextconfirmLabel {
  font-size: 13px;
  letter-spacing: 0px;
  color: #ffffff;
  margin-right: 15px;
  padding: 0px 15px;
  border-radius: 20px;
}
.fa-exclamation {
  color: red;
  font-size: x-large;
  padding: 0px 0px;
}
.ui-dialog-titlebar-close:before {
  content: "";
  font-family: FontAwesome;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 20px;
}
.ui-dialog .ui-dialog-titlebar-close {
  position: absolute;
  right: 0.3em;
  top: 50%;
  width: 19px;
  margin: -20px 0 0 0;
  padding: 1px;
  height: 18px;
  font-size: 20px;
  font-weight: 400;
  line-height: 19.5px;
  text-shadow: none;
}
.blacktooltip {
  text-align: left;
  z-index: 1;
  position: absolute;
  width: 150px;
  height: auto;
  font: normal normal normal 13px/18px Open Sans;
  border-radius: 10px;
}
.blacktooltip .insideDivUl {
  list-style: none;
  padding: 5px;
  display: flex;
  flex-direction: column;
  margin-top: 0;
  margin-bottom: 0rem;
}
.blacktooltip .insideDivUl li {
  height: 38px;
  line-height: 38px;
  cursor: default;
}
@media (max-width: 979px) {
  .page-footer {
    padding: 5px 14px 0;
  }
}
@media (min-width: 321px) and (max-width: 479px) {
  #left-panel {
    top: 162px !important;
  }
}
@media (min-width: 480px) and (max-width: 576px) {
  #left-panel {
    top: 162px !important;
  }
}
@media (min-width: 577px) and (max-width: 803px) {
  #left-panel {
    top: 155px !important;
  }
}
@media (max-width: 320px) {
  #left-panel {
    top: 255px !important;
  }
}
@media (min-width: 577px) and (max-width: 803px) {
  #left-panel {
    top: 155px !important;
  }
}
@media (max-width: 992px) {
  #fullscreen .svg-icon {
    width: 40px !important;
  }

  #header #activity.activity-svg {
    width: 40px !important;
  }

  #header .notification-svg {
    width: 40px !important;
  }

  #header .menu-item_pading {
    padding: 0 10px 0 0 !important;
  }
}
.scroll-content::-webkit-scrollbar {
  width: 1px;
}
.scroll-content {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
  overflow-x: none;
}
.MessageBoxButtonSection button {
  border-radius: 15px;
  background: transparent;
  border: 2px solid var(--theme-color-1) !important;
  font-size: 13px;
  padding: 3px 15px;
  color: #ffffff !important;
  font-weight: 400 !important;
}
.MessageBoxButtonSection button:hover {
  border-radius: 15px;
  background: var(--theme-color-1) !important;
  border: 2px solid var(--theme-color-1) !important;
  font-size: 13px;
  padding: 3px 15px;
  color: #000 !important;
}
.contact_infrmtn_sctn .detailLbl {
  color: #ffffff;
  font-size: 14px;
}
.contact_infrmtn_sctn .detailLbl .commoninputStyle .form-control {
  height: 40px !important;
  border-radius: 6px !important;
  background-color: #171819;
  border: 1px solid #171819;
  font-size: 14px;
  color: #ffffff;
}
.comapny_details_form .detailLbl {
  color: #ffffff;
  font-size: 14px;
}
.comapny_details_form .detailLbl .commoninputStyle .form-control {
  height: 40px !important;
  border-radius: 6px !important;
  background-color: #171819;
  border: 1px solid #171819;
  font-size: 14px;
  color: #ffffff;
}
.detail_save_btn,
.detail_save_Btn,
.approveBtn,
.detailInfo_save_btn,
.iom_btn {
  border-radius: 15px;
  background: transparent;
  border: 2px solid var(--theme-color-1);
  font-size: 13px;
  padding: 3px 15px;
  line-height: 19.5px;
  font-weight: 400;
}
.detail_save_btn:hover,
.detail_save_Btn:hover,
.approveBtn:hover,
.detailInfo_save_btn:hover,
.iom_btn:hover {
  border-color: var(--theme-color-1) !important;
  background: var(--theme-color-1) !important;
  color: var(--hovertext-color) !important;
}
.iom_btn[disabled] {
  box-shadow: none;
  cursor: not-allowed;
  opacity: 0.65;
}
.editDisabled {
  background: none;
}
.editEnabled {
  background-color: #171819 !important;
}
.border-line {
  background: transparent !important;
  border: none;
}
.nextBtn {
  font-size: 13px;
  background: transparent 0% 0% no-repeat padding-box !important;
  border-color: var(--theme-color-1) !important;
  letter-spacing: 0px;
  border: 2px solid var(--theme-color-1) !important;
  border-radius: 15px;
  color: #ffffff !important;
  padding: 3px 15px;
}
.nextBtn:hover {
  background: var(--theme-color-1) !important;
  border: 2px solid var(--theme-color-1);
  color: var(--hovertext-color) !important;
}
.nextstep {
  font-size: 13px;
  background: transparent 0% 0% no-repeat padding-box !important;
  border-color: var(--theme-color-1) !important;
  letter-spacing: 0px;
  border: 2px solid var(--theme-color-1) !important;
  border-radius: 15px;
  color: #ffffff !important;
  padding: 3px 15px;
}
.nextstep:hover {
  background: var(--theme-color-1) !important;
  border: 2px solid var(--theme-color-1);
  color: var(--hovertext-color) !important;
}
.cancelBtn {
  border: 2px solid var(--theme-color-1);
  border-radius: 15px;
  color: #ffffff !important;
  font-size: 13px;
  padding: 3px 15px !important;
}
.cancelBtn:hover {
  background: var(--theme-color-1) !important;
  border: 2px solid var(--theme-color-1);
  color: var(--hovertext-color) !important;
}
.butttonBluecolor {
  background-color: #202528 !important;
  border: 2px solid var(--theme-color-1) !important;
  color: #ffffff !important;
  border-radius: 15px;
  padding: 3px 15px;
}
.butttonBluecolor:hover {
  background: var(--theme-color-1) !important;
  border: 2px solid var(--theme-color-1);
  color: var(--hovertext-color) !important;
}
.lightBackgroundColor {
  background-color: #0C1214 !important;
}
.noscroll {
  overflow-y: hidden !important;
}
.outerMainDiv {
  padding: 20px !important;
  max-height: calc(100vh - 100px) !important;
  overflow-y: auto !important;
}
.PaySctn input[type=radio]:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: var(--theme-color-1);
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid #666666;
  cursor: pointer;
}
.gridSectionFirst .payMethodSection {
  padding: 11px 10px 10px 15px;
  margin: 20px 0px 0px 0px;
  width: 100%;
  border-radius: 10px;
  min-height: 123px;
}
.gridSectionFirst .payMethodSection .payHead span {
  font-size: 16px;
  font-weight: 400;
}
.gridSectionFirst .payMethodSection .payLink {
  padding: 3px 5px 0px 15px;
}
.gridSectionFirst .payMethodSection .payLink span {
  font-size: 14px;
  color: var(--theme-color-1);
  cursor: pointer;
}
.gridSectionFirst .techContactSection {
  padding: 11px 30px 10px 30px;
  margin: 20px 0px 0px 0px;
  width: 100%;
  border-radius: 10px;
  height: auto;
}
.gridSectionFirst .techContactSection .techHead {
  font-size: 16px;
  font-weight: 400;
}
.gridSectionFirst .techContactSection > .row {
  display: block;
}
.next_payment {
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 14px;
  min-width: 60%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.applicationHead {
  margin-top: 15px;
  height: 34px;
  opacity: 1;
  border: unset;
}
.tranhoverTable input[type=radio]:checked:after {
  width: 20px;
  height: 20px;
  border-radius: 15px;
  top: -1px;
  left: -1px;
  position: relative;
  background-color: var(--theme-color-1);
  content: "";
  display: inline-block;
  visibility: visible;
}
.tranhoverTable input[type=radio] {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border: 1px solid darkgray;
  border-radius: 50%;
  outline: none;
}
.noBorder {
  border: none !important;
}
.activeUser .dt-toolbar-footer {
  border: none;
  background: none;
}
.userEditTextBox {
  background: #ffffff 0% 0% no-repeat padding-box !important;
}
.nameDiv,
.headDiv {
  color: #202528;
}
.user-table .table tbody tr:first-child td {
  border-top: none !important;
}
.rightBorder {
  border-right: 2px solid var(--theme-color-1) !important;
  border-left: 2px solid var(--theme-color-1) !important;
}
.topBorder {
  border-top: 2px solid var(--theme-color-1) !important;
}
.bottomBorder {
  border-bottom: 2px solid var(--theme-color-1) !important;
}
.selected {
  background-color: var(--theme-color-1) !important;
  border-color: var(--theme-color-1) !important;
}
.gridSectionTwo .billingContactSection {
  height: auto;
  padding: 10px 30px;
  margin: 20px 0px 0px 20px;
  width: 96%;
  border-radius: 10px;
}
.gridSectionTwo .billingContactSection > .row {
  display: block;
}
.fixed-head {
  position: sticky;
  top: -14px;
  z-index: 100;
}
table.table-bordered.dataTable {
  border-collapse: none !important;
}
.user-table .dt-toolbar-footer {
  display: none !important;
}
.dt-toolbar-footer {
  background: none !important;
  width: 100% !important;
  font-size: none !important;
  overflow: hidden;
  padding: none !important;
  border-top: none !important;
  -ms-box-shadow: none !important;
  box-shadow: none !important;
}
.dataTables_processing {
  display: block;
  text-indent: -99999px;
  border: 0px !important;
  box-shadow: none !important;
  z-index: 999;
  margin-top: 15px !important;
  background-position: center !important;
}
.dt-toolbar {
  background: none !important;
  border: none !important;
}
table.pending-list-table {
  border-collapse: separate !important;
}
table.pending-list-table thead tr th:first-child {
  border-radius: 5px 0px 0px 0px !important;
}
table.pending-list-table thead tr th:last-child {
  border-radius: 0px 5px 0px 0px !important;
}
table.pending-list-table tbody tr:last-child td:first-child {
  border-radius: 0px 0px 0px 5px !important;
}
table.pending-list-table tbody tr:last-child td:last-child {
  border-radius: 0px 0px 5px 0px !important;
}
.table thead:first-child tr:first-child th {
  border-bottom: none !important;
}
button.disabled {
  cursor: no-drop !important;
  pointer-events: none !important;
  opacity: 0.3;
}
.badge-iom {
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  font-size: 13px !important;
  padding: 4px 13px !important;
  background-color: var(--theme-color-1) !important;
  color: #000;
  border-radius: 10rem;
}
.badge-disable-iom {
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  font-size: 13px !important;
  padding: 4px 13px !important;
  border-radius: 10rem;
  cursor: no-drop;
  background: transparent;
  border: 2px solid var(--theme-color-1);
}
.MessageBoxMiddle .MsgTitle {
  letter-spacing: -1px;
  font-size: 24px;
  font-weight: 300;
  margin-left: -28px;
}
.pure-checkbox input[type=checkbox] + label:after {
  display: none !important;
}
.pure-checkbox input[type=checkbox] {
  position: relative !important;
  height: 15px !important;
  width: 15px !important;
}
.dropdown-list.fixMultiSelect {
  display: block !important;
  position: static !important;
}
.custom-scroll::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}
#userRolePopup {
  max-height: 445px !important;
}
#userRolePopup footer {
  padding: 1rem 2rem;
  margin-top: 2rem;
}
#userRolePopup .dropdown-multiselect__caret {
  display: none !important;
}
.serviceTheme {
  border-radius: 10px !important;
}
.rolePopUp {
  max-height: calc(100% - 100px);
  overflow-y: auto;
  border-radius: 10px;
}
.user-role-form .multiselect-dropdown .dropdown-btn {
  border: unset !important;
}
.user-role-form .multiselect-dropdown .dropdown-btn .selected-item-container .selected-item {
  border: 1px solid var(--theme-color-1) !important;
  background: var(--theme-color-1) !important;
  color: #000 !important;
  max-width: unset !important;
  padding: 3px 10px !important;
  border-radius: 20px !important;
  margin-right: 10px !important;
}
.user-role-form .multiselect-dropdown .dropdown-btn .selected-item-container .selected-item a {
  color: #000 !important;
}
.user-role-form .multiselect-dropdown .filter-textbox input {
  background: #171717 !important;
}
.user-role-form .multiselect-dropdown .multiselect-item-checkbox input + div:before {
  color: #ffffff !important;
  border: 2px solid #ffffff !important;
  border-radius: 5px;
}
.user-role-form .multiselect-dropdown .multiselect-item-checkbox input + div:focus {
  color: #ffffff !important;
  border: 2px solid #ffffff !important;
}
.user-role-form .multiselect-dropdown .multiselect-item-checkbox input[type=checkbox]:checked + div:before {
  background: transparent !important;
}
.price_calcultation_form .multiselect-dropdown {
  width: 98.5% !important;
  height: 40px !important;
  padding: 4px 0px 0px;
  font: 13px/16px "Open Sans", Helvetica, Arial, sans-serif;
}
.price_calcultation_form .multiselect-dropdown .dropdown-btn {
  border-radius: 10px !important;
  padding: 8px 12px !important;
}
.price_calcultation_form .multiselect-dropdown .dropdown-btn span {
  padding-left: 5px;
}
.price_calcultation_form .multiselect-dropdown .dropdown-btn .selected-item-container .selected-item {
  border: 1px solid var(--theme-color-1) !important;
  background: var(--theme-color-1) !important;
  color: #000 !important;
  max-width: unset !important;
  padding: 0px 5px !important;
  border-radius: 20px !important;
  box-shadow: 1px 1px var(--theme-color-1) !important;
}
.price_calcultation_form .multiselect-dropdown .dropdown-btn .selected-item-container .selected-item a {
  color: #000 !important;
}
.price_calcultation_form .multiselect-dropdown .dropdown-btn .dropdown-multiselect__caret {
  display: none !important;
}
.price_calcultation_form .multiselect-dropdown .filter-textbox input {
  background: #171717 !important;
}
.price_calcultation_form .multiselect-dropdown .multiselect-item-checkbox input + div:before {
  color: #ffffff !important;
  border: 2px solid #ffffff !important;
  border-radius: 5px;
}
.price_calcultation_form .multiselect-dropdown .multiselect-item-checkbox input + div:focus {
  color: #ffffff !important;
  border: 2px solid #ffffff !important;
}
.price_calcultation_form .multiselect-dropdown .multiselect-item-checkbox input[type=checkbox]:checked + div:before {
  background: transparent !important;
}
.price_calcultation_form .multiselect-dropdown .dropdown-multiselect__caret {
  top: 5px !important;
}
.btn.focus {
  box-shadow: 0 0 0 0.2rem rgba(182, 235, 0, 0.25) !important;
}
.btn:focus {
  box-shadow: 0 0 0 0.2rem rgba(182, 235, 0, 0.25) !important;
}
.toolTipHideUserRole {
  width: 285px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: 0;
}
.roleLabel {
  width: 340px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: justify;
}
.tooltipRole {
  position: relative;
  display: inline-block;
}
.tooltipRole:hover {
  cursor: pointer;
}
.tooltipRole:hover .tooltiptextRole {
  visibility: visible;
}
.tooltipRole:hover .tooltiptextRole .insideDivUl li:last-child {
  border-bottom: unset;
}
.tooltipRole .tooltiptextRole {
  visibility: hidden;
  text-align: center;
  border-radius: 6px;
  padding: 5px 5px;
  word-break: break-all;
  position: fixed;
  z-index: 1;
  left: 38%;
  top: 0%;
  text-align: left;
  width: auto;
  font: normal normal normal 13px/18px Open Sans;
  border-radius: 10px;
  height: auto;
}
.tooltipRole .tooltiptextRole .insideDivUl {
  list-style: none;
  padding: 5px;
  display: flex;
  flex-direction: column;
  margin-top: 0;
  margin-bottom: 0rem;
}
.tooltipRole .tooltiptextRole .insideDivUl li {
  height: 38px;
  line-height: 38px;
  cursor: default;
}
.tooltipRole .toolTipRoleTitle {
  padding: 5px 5px !important;
  left: inherit !important;
  width: -moz-max-content;
  width: max-content;
  z-index: 1000;
}
.minified .profile-image-box {
  display: none;
}
.minified ul.navList {
  margin-top: -25px !important;
}
.minified .colDisplay {
  padding: 12px 0px !important;
}
.minified #clearCache .iom_btn {
  padding: 3px 13px !important;
}
.spin-loader {
  height: 25px;
  width: 25px;
  display: inline-block;
  margin: 13px 0px 0px 10px;
}
.big-spin-loader {
  width: 50px;
  height: 50px;
  position: absolute;
  left: 0px;
  right: 0px;
  margin: 5% auto;
  transform: translate(0, -50%);
  top: 50%;
  z-index: 99;
}
.serviceTheme .modal_header {
  padding: 0 13px;
  text-align: left;
  letter-spacing: 0px;
  opacity: 1;
  color: #ffffff;
  font-size: 16px;
}
.card-fields .form-fields-wrapper .form-fields {
  width: calc(100% - 35px);
  margin-left: auto;
}
.cardNumber {
  margin-bottom: 7px;
}
.cvv {
  margin-top: 22px;
}
/* Checkbox style */
.checkbox-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 17px;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
/* Create a custom checkbox */
.checkmark {
  position: absolute;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 7px;
  background-color: transparent;
}
/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkmark {
  background-color: #ccc;
}
/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
  background-color: transparent;
}
/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}
/* Create a custom checkbox */
.checkcontractsmark {
  position: absolute;
  left: 0;
  height: 17px;
  width: 17px;
  border-radius: 7px;
  background-color: transparent;
}
/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkcontractsmark {
  background-color: #ccc;
}
/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkcontractsmark {
  background-color: transparent;
}
/* Create the checkcontractsmark/indicator (hidden when not checked) */
.checkcontractsmark:after {
  content: "";
  position: absolute;
  display: none;
}
/* Show the checkcontractsmark when checked */
.checkbox-container input:checked ~ .checkcontractsmark:after {
  display: block;
}
/* Style the checkmark/indicator */
.pagination > li:last-child > a, .pagination > li:last-child > span {
  border-bottom-right-radius: 5px !important;
  border-top-right-radius: 5px !important;
}
.pagination > li:first-child > a, .pagination > li:first-child > span {
  border-bottom-left-radius: 5px !important;
  border-top-left-radius: 5px !important;
}
button[disabled] {
  cursor: no-drop !important;
}
html input[disabled] {
  cursor: no-drop !important;
}
.modalContent {
  padding: 20px;
  height: 200px;
}
.modalContent .modalBody {
  border-radius: 10px !important;
  padding: 0px;
}
.modalContent .modalHead {
  border: unset !important;
  padding: 0px;
  margin-bottom: 10px;
  display: inline-block;
}
.modalContent .modalHead .modalTitle {
  text-align: left;
  font: normal normal bold 16px/22px Open Sans;
  letter-spacing: 0px;
}
.table-wrapper table {
  margin-bottom: 0px !important;
}
.dt-toolbar-footer {
  padding: 5px 0px;
}
.dropdown-list {
  box-shadow: none !important;
}
.header-formbtn {
  width: auto;
  margin-top: 1px !important;
  border: none;
  background: #ffffff !important;
  text-align: center !important;
  letter-spacing: 0;
  color: #073E84 !important;
  font-weight: bold;
  font: normal normal normal 13px/18px Open Sans !important;
  border-radius: 15px;
  margin-left: 10px;
}
.subPlanData {
  padding: 20px 0 0;
}
.subPlanData .content {
  margin-top: 10px;
}
.subPlanData .content p {
  font-size: 16px !important;
}
.comingSoon {
  text-align: left;
  z-index: 1001;
  position: absolute;
  width: auto;
  max-width: -moz-max-content;
  max-width: max-content;
  height: auto;
  padding: 10px;
  font: normal normal normal 13px/18px Open Sans;
  border-radius: 10px;
  opacity: 1;
  font-weight: bold;
  background-color: var(--theme-color-1) !important;
  margin-top: -10px;
}
.crossBtn {
  text-align: right;
  letter-spacing: 0px;
  color: var(--theme-color-1);
  opacity: 1;
  font-size: 20px;
  font-weight: bold;
}
input:-internal-autofill-selected {
  background-color: red !important;
}
.borderClassForm input, .borderClassForm select {
  border-color: #dc3545 !important;
}
.radioMargin {
  margin-top: -2px;
}
.pe-none {
  pointer-events: none;
}
.greentext {
  color: var(--theme-color-1) !important;
}
.textalignlabel {
  text-align: center;
  width: auto;
  padding: 10px 15px;
}
.user-list {
  font-size: 14px;
  min-height: 170px;
  max-height: 320px;
  overflow-y: scroll;
}
.user-list::-webkit-scrollbar {
  width: 5px !important;
  height: 5px !important;
}
.user-list::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: transparent;
}
.user-list::-webkit-scrollbar-thumb {
  background-color: #CCC;
  border-radius: 5px;
}
.ui-datepicker {
  border-radius: 5px !important;
  z-index: 2000 !important;
}
.ui-datepicker select.ui-datepicker-month {
  margin: 0 2px;
  width: 47%;
}
.ui-datepicker select.ui-datepicker-year {
  margin: 0 2px;
  width: 47%;
}
.ui-datepicker td .ui-state-default {
  color: #ffffff;
}
.ui-datepicker .ui-datepicker-next span {
  background: none !important;
}
.ui-datepicker .ui-datepicker-prev span {
  background: none !important;
}
.ui-datepicker-prev span {
  background-image: none !important;
}
.ui-datepicker-next span {
  background-image: none !important;
}
.ui-datepicker-prev:before {
  font-family: FontAwesome;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  font-weight: normal;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  z-index: 9;
  padding-top: 4px;
  content: "";
}
.ui-datepicker-next:before {
  font-family: FontAwesome;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  font-weight: normal;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  z-index: 9;
  padding-top: 4px;
  content: "";
}
.setCircleTest {
  color: #F1DA91;
  font-size: 10px !important;
}
.commonBasebg {
  background-color: var(--theme-color-1) !important;
}
.minified nav > ul > li > a > .menu-item-parent {
  top: 1px !important;
}
/*
---------------------------------------------------------------------------------------
  Dark theme style
---------------------------------------------------------------------------------------
*/
.smart-style-1 #main {
  height: 100% !important;
}
.smart-style-1 .NextconfirmLabels {
  color: #ffffff;
  border: 1px solid #333;
}
.smart-style-1 .NextconfirmLabel {
  border: 1px solid #333;
}
.smart-style-1 body {
  background-color: #1E1E1E !important;
}
.smart-style-1 #ribbon .breadcrumb li:last-child {
  color: #000 !important;
}
.smart-style-1 .breadcrumb > li + li:before {
  color: #000;
}
.smart-style-1 .auth-text-color {
  color: #0D0F12 !important;
}
.smart-style-1 #left-panel nav ul li {
  border-bottom: 1px solid var(--theme-color-1);
}
.smart-style-1 #left-panel nav ul li:hover {
  background: var(--theme-color-1) !important;
  color: var(--hovertext-color) !important;
  border: 1px solid #333;
  border-bottom: 1px solid #333 !important;
}
.smart-style-1 #left-panel nav ul li.active {
  background-color: var(--theme-color-1) !important;
}
.smart-style-1 #left-panel nav ul li.active a {
  color: var(--hovertext-color) !important;
}
.smart-style-1 #ribbon .breadcrumb li {
  color: #8B91A0;
}
.smart-style-1 #ribbon .breadcrumb li:last-child {
  color: #ffffff !important;
}
.smart-style-1 .theme-header-text-color {
  color: #ffffff !important;
}
.smart-style-1 .theme-subtext-color {
  color: #959595 !important;
}
.smart-style-1 #content {
  background: #202124 !important;
}
.smart-style-1 .outerMainDiv {
  background: #202124 !important;
}
.smart-style-1 .dashabord .jarviswidget div {
  background-color: #0C1214 !important;
  border: 1px solid #333 !important;
}
.smart-style-1 .theme-icon {
  color: #ffffff !important;
}
.smart-style-1 .theme-text-color {
  color: #E2E2E2 !important;
}
.smart-style-1 .ajax-dropdown {
  border: 1px solid #333 !important;
}
.smart-style-1 .notification-body li {
  border: 1px solid #333 !important;
}
.smart-style-1 .dropdown-menu {
  background-color: #0C1214;
  border: 1px solid #333;
}
.smart-style-1 .dropdown-menu li a {
  color: #ffffff;
}
.smart-style-1 .header-dropdown-list .dropdown-menu li a:hover {
  background-color: #1E1E1E !important;
}
.smart-style-1 .header-dropdown-list .dropdown-menu .active a {
  background-color: #1E1E1E !important;
}
.smart-style-1 .applicationHead {
  background: #202124;
  color: #ffffff;
}
.smart-style-1 #fullscreen .svg-icon {
  background-image: url('Path 222.737b381f1c9fbc85a137.svg');
}
.smart-style-1 #header .notification-svg {
  background-image: url('39-Notification.4feddd112452285fe534.svg');
}
.smart-style-1 #header #activity.activity-svg {
  background-image: url('Page-1.9f2dc138f1ca7885f995.svg');
}
.smart-style-1 #header .collapseMenu .collaspe-menu-svg {
  background-image: url('Path 224.c485c714dd85f9b4f52d.svg');
}
.smart-style-1 #header .collapseMenu .collaspe-menu-sub-svg {
  background-image: url('Group224.649ca2f656deeb39e0a7.svg');
  cursor: pointer;
}
.smart-style-1 #header .collapseMenu:hover {
  background-color: #0D0F12;
}
.smart-style-1 #header .header-btn {
  background-color: #1E1E1E;
}
.smart-style-1 #header .header-btn-list a {
  background-color: #1E1E1E !important;
}
.smart-style-1 #header .menu-item:hover {
  background-color: #333;
}
.smart-style-1 #header .theme-text-color {
  color: #ffffff !important;
}
.smart-style-1 #header .menu-item.no-hover:hover {
  background-color: #1E1E1E;
}
.smart-style-1 #header .menu-left-item {
  border-left: unset !important;
}
.smart-style-1 #header.colorChangeTest .header-btn {
  background-color: transparent;
  box-shadow: none;
}
.smart-style-1 #header.colorChangeTest .header-btn:hover {
  background-color: #1E1E1E;
}
.smart-style-1 #header.colorChangeTest .dropdown-icon-menu:hover .header-btn {
  background-color: #1E1E1E;
}
.smart-style-1 .jarviswidget-color-darken header {
  background: #171819 !important;
}
.smart-style-1 .upgradebtn {
  color: #ffffff !important;
}
.smart-style-1 .spanBoderBottom {
  border-bottom: 1px solid #333333 !important;
}
.smart-style-1 .modalBodyBgColor {
  background-color: #0C1214 !important;
}
.smart-style-1 .applicationHeadModal {
  background: #0D0F12 !important;
}
.smart-style-1 .theme-fade-color {
  color: #959595 !important;
}
.smart-style-1 .subList:not(:last-child) {
  border-bottom: 1px solid #202124 !important;
}
.smart-style-1 .border {
  border: 1px solid #333 !important;
  border-radius: 10px;
}
.smart-style-1 .userProfile .applicationBackGround {
  background-color: #202124 !important;
}
.smart-style-1 .menuSelected {
  background-color: #333;
}
.smart-style-1 .border-line {
  border: none;
  background-color: #202124 !important;
}
.smart-style-1 .account-table td {
  color: #ffffff !important;
}
.smart-style-1 .account-table th {
  color: #ffffff !important;
  background-color: #1E1E1E !important;
}
.smart-style-1 table.account-table {
  margin-bottom: 0px !important;
}
.smart-style-1 .account-table {
  border-color: #333333 !important;
}
.smart-style-1 .account-table th {
  background-color: #0d0f12 !important;
  border-color: #333333 !important;
  border-top: none;
  border-bottom: none;
  text-align: left;
  font: normal normal bold 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #E2E2E2;
  color: #ffffff !important;
  padding-left: 24px !important;
}
.smart-style-1 .account-table tr:nth-child(odd) {
  background-color: #0d0f12 !important;
}
.smart-style-1 .account-table td {
  border-color: #333333 !important;
  font: normal normal normal 13px/18px Open Sans;
  letter-spacing: 0px;
  color: #E2E2E2;
  color: #ffffff !important;
  padding-left: 24px !important;
}
.smart-style-1 .account-table .history_action {
  text-decoration: underline !important;
}
.smart-style-1 .account-table-div .dt-toolbar-footer {
  background-color: #0d0f12 !important;
  border: 1px solid #333 !important;
  margin-top: -6px;
  box-shadow: none !important;
  width: 100% !important;
}
.smart-style-1 .account-table-div .dt-toolbar-footer .dataTables_info {
  text-align: left !important;
  font: italic normal 600 13px/18px Open Sans !important;
  letter-spacing: 0px !important;
  color: #959595 !important;
}
.smart-style-1 .account-table-div .dt-toolbar-footer .pagination .paginate_button a {
  text-align: left !important;
  font: normal normal normal 11px/15px Open Sans !important;
  letter-spacing: 0px !important;
  color: #000 !important;
  background: #0d0f12 !important;
  border: 1px solid #333 !important;
}
.smart-style-1 .account-table-div .dt-toolbar-footer .pagination .paginate_button.active a {
  background-color: #E2E2E2 !important;
  color: #171717 !important;
}
.smart-style-1 nav ul li a {
  color: #ffffff !important;
  font-size: 16px !important;
}
.smart-style-1 nav ul li a:focus, .smart-style-1 nav ul li a:hover {
  background: var(--theme-color-1) !important;
  color: #000 !important;
}
.smart-style-1 .model-7 .checkbox label:after {
  background: #000 !important;
}
.smart-style-1 .body-image {
  border-radius: 100%;
  height: 200px;
}
.smart-style-1 .table tbody tr td {
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 1px solid #333;
  border-color: #333333;
}
.smart-style-1 .lstTrncnTbl thead tr th {
  background-color: #1E1E1E !important;
  color: #ffffff;
  border-color: #333333;
}
.smart-style-1 .lstTrncnTbl tbody tr.odd {
  color: #ffffff;
  /*background-color: $table-backgroundcolor;*/
}
.smart-style-1 .lstTrncnTbl tbody tr.even {
  color: #ffffff;
  /*background-color: map.get($darktheme-colors, "bgcolor");*/
}
.smart-style-1 .lstTrncnTbl tbody td a {
  color: var(--theme-color-1);
}
.smart-style-1 .lstTrncnTbl .table-bordered {
  border-color: #707070 !important;
}
.smart-style-1 .lstTrncnTbl .dt-toolbar {
  background: none !important;
  border-bottom: none;
}
.smart-style-1 .emptyCol {
  border-top-right-radius: 0px !important;
  border-top: 1px solid transparent !important;
  border-left: 1px solid transparent !important;
  background-color: #202124 !important;
  border-right: 1px solid transparent !important;
  border-bottom: 1px solid transparent !important;
}
.smart-style-1 .currentPlan {
  background-color: #CCCCCC !important;
  color: #202528 !important;
}
.smart-style-1 .currentPlan:hover {
  background: #CCCCCC !important;
}
.smart-style-1 .currentButton {
  background-color: #404040 !important;
  color: #666666 !important;
  border-color: #333 !important;
  cursor: not-allowed;
  z-index: 1;
}
.smart-style-1 .model-7 .checkbox label:after {
  background: #000 !important;
}
.smart-style-1 .pagination > .active > a {
  background-color: var(--theme-color-1) !important;
  border-color: var(--theme-color-1) !important;
  color: #000 !important;
  border-color: #333;
}
.smart-style-1 .pagination > li > a {
  background-color: transparent !important;
  color: #ffffff;
  border-color: #333;
}
.smart-style-1 .pagination > li:first-child > a,
.smart-style-1 .pagination > li:first-child > span {
  margin-left: 0;
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
  background-color: transparent !important;
  color: #ffffff;
  border-color: #333;
}
.smart-style-1 .pagination > li:last-child > a,
.smart-style-1 .pagination > li:last-child > span {
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  background-color: transparent !important;
  border-color: #333;
}
.smart-style-1 .selectButton {
  border: 2px solid #333;
  border-radius: 15px;
  padding: 3px 15px;
  background-color: #202124;
  border-color: #333;
  font-size: 14px;
  outline: none;
}
.smart-style-1 .selectButton:hover {
  background: var(--theme-color-1) !important;
  color: var(--hovertext-color) !important;
}
.smart-style-1 .cancel {
  text-align: left;
  width: auto;
  border-radius: 15px;
  background: #202124 0% 0% no-repeat padding-box !important;
  border: 2px solid #333 !important;
  font-size: 13px;
  padding: 3px 15px;
}
.smart-style-1 .TotalLabel {
  top: 275px;
  left: 560px;
  height: 15px;
  text-align: left;
  font: normal normal bold 31px/23px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}
.smart-style-1 .paymentSectionDetails {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
  border-radius: 10px;
  margin-left: -1px;
}
.smart-style-1 .paymentOptions {
  display: none;
  border-radius: 10px;
}
.smart-style-1 .lightBackgroundColor {
  border-radius: 10px;
  background-color: #202124 !important;
  border: 1px solid #333 !important;
  margin-left: 13px;
}
.smart-style-1 .left-div {
  border-radius: 10px;
  background-color: #202124 !important;
}
.smart-style-1 .card-div {
  border-radius: 5px;
  background-color: #202124 !important;
  border: 1px solid #333 !important;
  padding-bottom: 0px;
}
.smart-style-1 .card-div:hover {
  background-color: #171819 !important;
}
.smart-style-1 .pricingTable table {
  color: #ffffff !important;
}
.smart-style-1 .theme-bg-color {
  background-color: #202124 !important;
}
.smart-style-1 .theme-subtext-color {
  color: #959595 !important;
}
.smart-style-1 #content {
  background: #202124 !important;
}
.smart-style-1 .jarviswidget > div {
  background: transparent !important;
  border: none;
}
.smart-style-1 .bootstrapWizard li.active .title,
.smart-style-1 .bootstrapWizard li.complete .title {
  color: #ffffff;
}
.smart-style-1 .bootstrapWizard li .step {
  background-color: #202124;
  border: 1px solid #333;
  color: #EEE;
}
.smart-style-1 .headData {
  background-color: #202124;
  color: #ffffff;
  margin-top: 10px;
  font-size: 14px;
}
.smart-style-1 .modal-body {
  background-color: #171717 !important;
}
.smart-style-1 .modal-content {
  background-color: #171717 !important;
  border: 1px solid #333;
  color: #ffffff;
  border-radius: 10px;
}
.smart-style-1 .modal-footer {
  padding: 20px 0px 0px 0px;
  border-top: none;
}
.smart-style-1 .back-btn {
  background: #202124 0% 0% no-repeat padding-box;
  border: 2px solid #333 !important;
  font-size: 13px;
  border-radius: 20px;
}
.smart-style-1 .jarviswidget-color-darken > header {
  background: none !important;
  border: none;
}
.smart-style-1 .theme-content-color {
  color: #FFFFFF;
}
.smart-style-1 .theme-button-color {
  background-color: #202124;
  color: #ffffff !important;
}
.smart-style-1 .tranhoverTable select {
  background-color: inherit;
  color: inherit;
  border: 1px solid #333;
  border-radius: 5px;
}
.smart-style-1 .tranhoverTable select option {
  background: #202528 0% 0% no-repeat padding-box;
  color: inherit;
}
.smart-style-1 .tranhoverTable input[type=radio]:checked:after {
  border: 2px solid #333;
}
.smart-style-1 .contact_infrmtn_sctn .detailLbl {
  color: #ffffff;
  font-size: 14px;
}
.smart-style-1 .contact_infrmtn_sctn .detailLbl .commoninputStyle .form-control {
  height: 40px !important;
  border-radius: 6px !important;
  background-color: #171819 !important;
  border: 1px solid #333 !important;
  font-size: 14px;
  color: #ffffff;
}
.smart-style-1 .comapny_details_form .detailLbl {
  color: #ffffff;
  font-size: 14px;
}
.smart-style-1 .comapny_details_form .detailLbl .commoninputStyle .form-control {
  height: 40px !important;
  border-radius: 6px !important;
  background: #171819 !important;
  border: 1px solid #333;
  font-size: 13px;
  color: #ffffff;
}
.smart-style-1 .editEnabled .detailLbl {
  color: #ffffff !important;
  font-size: 14px !important;
}
.smart-style-1 .editEnabled .detailLbl .commoninputStyle .form-control {
  background-color: #202124 !important;
}
.smart-style-1 .detail_save_btn,
.smart-style-1 .detail_save_Btn,
.smart-style-1 .detailInfo_save_btn,
.smart-style-1 .approveBtn,
.smart-style-1 .iom_btn {
  color: #ffffff;
}
.smart-style-1 .editDisabled {
  background: none;
}
.smart-style-1 .gridSectionFirst .payMethodSection {
  border: 1px solid #333;
}
.smart-style-1 .gridSectionFirst .payMethodSection .payHead span {
  color: #ffffff;
}
.smart-style-1 .gridSectionFirst .techContactSection {
  border: 1px solid #333;
}
.smart-style-1 .gridSectionFirst .techContactSection .techHead {
  color: #ffffff;
}
.smart-style-1 .technical_details_form .detailLbl {
  color: #ffffff;
  font-size: 14px;
}
.smart-style-1 .technical_details_form .detailLbl .form-control {
  height: 40px !important;
  border-radius: 6px !important;
  background-color: #171819 !important;
  border: 1px solid #333;
  font-size: 13px;
  color: #ffffff;
}
.smart-style-1 .gridSectionTwo .billingContactSection {
  border: 1px solid #333;
}
.smart-style-1 .gridSectionTwo .billingContactSection .billHead {
  font-size: 16px;
  color: #ffffff;
  font-weight: 400;
}
.smart-style-1 .billing_details_form .detailLbl {
  color: #ffffff;
  font-size: 14px;
}
.smart-style-1 .billing_details_form .detailLbl .form-control {
  height: 40px !important;
  border-radius: 6px !important;
  background-color: #171819 !important;
  border: 1px solid #333;
  font-size: 13px;
  color: #ffffff;
}
.smart-style-1 .gridSectionThree .estimatedBillSection {
  padding: 10px 30px;
  border: 1px solid #333;
  margin: 20px 0px 20px 20px;
  width: 96%;
  border-radius: 10px;
  height: 584px;
}
.smart-style-1 .gridSectionThree .estimatedBillSection hr {
  border-top: 1px solid #333 !important;
}
.smart-style-1 .gridSectionThree .estimatedBillSection .estBillHead {
  font-size: 16px;
  color: #ffffff;
  font-weight: 400;
}
.smart-style-1 .gridSectionThree .estimatedBillSection .billItemDesc {
  font-size: 14px;
  color: #ffffff;
}
.smart-style-1 .gridSectionThree .estimatedBillSection .billItemPrice {
  font-size: 14px;
  color: #ffffff;
}
.smart-style-1 .edit_details_form .detailLbl {
  color: #ffffff;
  font-size: 14px;
}
.smart-style-1 .edit_details_form .detailLbl .commoninputStyle .form-control {
  height: 40px !important;
  border-radius: 6px !important;
  background-color: #202124;
  border: 1px solid #333;
  font-size: 14px;
  color: #ffffff;
}
.smart-style-1 .underline {
  border-color: #333;
}
.smart-style-1 .subscription-sum {
  border-top: 2px solid #32383e !important;
}
.smart-style-1 .card-fields .form-fields-wrapper {
  border-bottom: 1px solid #d5e1ec;
}
.smart-style-1 .card-fields .form-fields-wrapper .icon {
  width: 25px;
  height: auto;
}
.smart-style-1 .card-fields .form-fields-wrapper .form-fields {
  width: calc(100% - 35px);
  margin-left: auto;
}
.smart-style-1 .card-fields .form-fields-wrapper .form-fields input {
  outline: none;
  border: none;
  width: 100%;
  line-height: 50px;
  padding: 0;
  color: #ffffff !important;
  font-weight: 500;
  font-size: 20px;
  background: transparent;
}
.smart-style-1 .card-fields .form-fields-wrapper .form-fields input::-webkit-input-placeholder {
  color: #333;
  font-size: 16px;
}
.smart-style-1 .card-fields .form-fields-wrapper .form-fields input::-moz-placeholder {
  color: #333;
  font-size: 16px;
}
.smart-style-1 .card-fields .form-fields-wrapper .form-fields input:-ms-input-placeholder {
  color: #333;
  font-size: 16px;
}
.smart-style-1 .card-fields .form-fields-wrapper .form-fields input :-moz-placeholder {
  color: #333;
  font-size: 16px;
}
.smart-style-1 .card-fields .checkbox {
  color: #005397;
}
.smart-style-1 .card-fields .card-errors {
  color: #f34646;
  font-size: 10px;
  margin: 10px 0 0 0;
}
.smart-style-1 .ElementsApp, .smart-style-1 .ElementsApp .InputElement {
  color: #ffffff !important;
  font-weight: 300;
  font-family: "Helvetica Neue", Helvetica, sans-serif;
  font-size: 18px;
}
.smart-style-1 .payment-detail {
  width: 100%;
  max-width: 1010px;
  background-color: #171717;
  margin: auto;
  padding: 10px 10px 6px 10px;
}
.smart-style-1 .nav ul .active > a {
  color: #ffffff !important;
  font-weight: 700;
}
.smart-style-1 .ElementsApp,
.smart-style-1 .ElementsApp .InputElement {
  color: #ffffff;
  font-weight: 300;
  font-family: "Helvetica Neue", Helvetica, sans-serif;
  font-size: 18px;
}
.smart-style-1 .sub-btn {
  border: 2px solid var(--theme-color-1) !important;
  color: #ffffff !important;
}
.smart-style-1 .sub-btn:hover {
  background: var(--theme-color-1) !important;
  color: #000 !important;
}
.smart-style-1 .manage-btn {
  font-size: 13px;
  border: 2px solid var(--theme-color-1) !important;
  border-radius: 15px;
  background-color: #202528 !important;
  color: #ffffff !important;
  padding: 3px 15px;
}
.smart-style-1 .manage-btn:hover {
  background: var(--theme-color-1) !important;
  color: #000 !important;
  border: 2px solid var(--theme-color-1) !important;
}
.smart-style-1 .ganerateButton {
  background: #171819 !important;
  color: #ffffff;
}
.smart-style-1 .stepsChecked {
  font-weight: 900;
  background-color: #333;
}
.smart-style-1 .next_payment {
  background-color: #171819 !important;
  color: #ffffff;
}
.smart-style-1 .userEditTextBox {
  background: #202124 0% 0% no-repeat padding-box !important;
  color: #ffffff;
}
.smart-style-1 .nameDiv,
.smart-style-1 .headDiv {
  color: #ffffff;
}
.smart-style-1 .selectExpiry .form-control {
  height: 40px !important;
  border-radius: 6px !important;
  background-color: #202124;
  border: 1px solid #333;
  font-size: 14px;
  color: #ffffff;
}
.smart-style-1 .selectExpiry span.ng-star-inserted {
  visibility: hidden;
}
.smart-style-1 .form-control.is-invalid {
  background-image: none !important;
  border-color: #dc3545 !important;
}
.smart-style-1 .profileEditTextBox {
  background: #202124 0% 0% no-repeat padding-box !important;
}
.smart-style-1 .profileModalBtn {
  padding: 10px;
  background-color: transparent !important;
  border: 2px solid var(--theme-color-1) !important;
  color: #ffffff !important;
  border-radius: 19px;
}
.smart-style-1 .profileModalBtn:hover {
  background: var(--theme-color-1) !important;
  color: #000 !important;
}
.smart-style-1 .sub-img {
  background: #404040 0% 0% no-repeat padding-box;
}
.smart-style-1 .row-divider {
  border-color: #333 !important;
}
.smart-style-1 .dot-div {
  color: #FFFFFF !important;
}
.smart-style-1 .table-bordered > tbody > tr > td {
  border: 1px solid #333;
}
.smart-style-1 .subscriptions-page .table-bordered > thead > tr > th:not(:first-child) {
  border: 1px solid #333;
}
.smart-style-1 .subscriptions-page table.noFeature.table-bordered > thead > tr > th {
  border: 1px solid #333;
}
.smart-style-1 .table-bordered > tbody.headData > tr > td.switchCol,
.smart-style-1 .table-bordered > thead.headData > tr > th.switchCol,
.smart-style-1 .table-bordered > tbody.table-headData > tr > td.switchCol {
  box-shadow: -1px 0 0 0 #333;
}
.smart-style-1 .table-bordered > tbody.headData > tr > td:first-child.switchCol,
.smart-style-1 .table-bordered > tbody.table-headData > tr > td:first-child.switchCol {
  box-shadow: 0 -1px 0 0 #333;
}
.smart-style-1 .emptyCol {
  border-top: 1px solid transparent !important;
  border-left: 1px solid transparent !important;
  background-color: #202124 !important;
  border-right: 1px solid transparent !important;
  border-bottom: 1px solid transparent !important;
  background: #202124 !important;
}
.smart-style-1 .currentPlan {
  background-color: #CCCCCC !important;
  color: #202528 !important;
}
.smart-style-1 .currentPlan:hover {
  background: #CCCCCC !important;
}
.smart-style-1 .model-7 .checkbox label:after {
  background: #000 !important;
}
.smart-style-1 .cancel {
  text-align: left;
  width: auto;
  border-radius: 15px;
  background: #202124 0% 0% no-repeat padding-box !important;
  border: 2px solid #333 !important;
  font-size: 13px;
  padding: 3px 15px;
}
.smart-style-1 .TotalLabel {
  top: 275px;
  left: 560px;
  height: 15px;
  text-align: left;
  font: normal normal bold 31px/23px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}
.smart-style-1 .paymentSectionDetails {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
  border-radius: 10px;
  margin-left: -1px;
}
.smart-style-1 .paymentOptions {
  display: none;
  border-radius: 10px;
}
.smart-style-1 .lightBackgroundColor {
  border-radius: 5px;
  background-color: #202124 !important;
  border: 1px solid #333;
  margin-left: 13px;
}
.smart-style-1 .pricingTable table {
  color: #ffffff !important;
}
.smart-style-1 .theme-bg-color {
  background-color: #202124 !important;
}
.smart-style-1 .theme-subtext-color {
  color: #959595 !important;
}
.smart-style-1 #content {
  background: #202124 !important;
}
.smart-style-1 .jarviswidget > div {
  border: none;
}
.smart-style-1 .bootstrapWizard li.active .title,
.smart-style-1 .bootstrapWizard li.complete .title {
  color: #ffffff;
}
.smart-style-1 .bootstrapWizard li .step {
  background-color: #202124;
  border: 1px solid #333;
  color: #eee;
}
.smart-style-1 .textColor {
  color: #ffffff !important;
}
.smart-style-1 .back-btn {
  background: #073E84 0% 0% no-repeat padding-box;
  border-color: #073E84;
  font-size: 13px;
}
.smart-style-1 .jarviswidget-color-darken > header {
  background: none !important;
}
.smart-style-1 .yearlabel {
  border: 1px solid #333;
  background-color: #202124;
}
.smart-style-1 .cancellabel {
  border: 1px solid #333;
  background-color: #202124;
}
.smart-style-1 .buy-addon .navigationList .nav.nav-tabs li a span {
  color: #ffffff !important;
}
.smart-style-1 .buy-addon .navigationList .nav.nav-tabs li a:hover {
  background-color: var(--theme-color-1) !important;
  border: 1px solid #333 !important;
}
.smart-style-1 .buy-addon .navigationList .nav.nav-tabs li a:hover span {
  color: #000 !important;
}
.smart-style-1 .buy-addon .navigationList .nav.nav-tabs li.active a span {
  color: #000 !important;
}
.smart-style-1 .buy-addon .checkout {
  background-color: #202528;
}
.smart-style-1 .table-headData {
  background-color: #202124;
  color: #ffffff;
  margin-top: 10px;
}
.smart-style-1 .commoninputStyle .form-control {
  height: 40px !important;
  border-radius: 6px !important;
  background-color: #202124;
  border: 1px solid #333;
  font-size: 14px;
  color: #ffffff !important;
}
.smart-style-1 .fieldForm .form-control {
  background-color: #202124;
  border: 1px solid #333;
  color: #ffffff !important;
}
.smart-style-1 .child .dtr-details .dtr-title {
  color: #E2E2E2 !important;
}
.smart-style-1 .child .dtr-details .dtr-data {
  color: #E2E2E2 !important;
}
.smart-style-1 .cart-btn {
  background-color: #ffffff;
}
.smart-style-1 .cart-btn .fas {
  color: #202528;
}
.smart-style-1 .blacktooltip {
  background: #202124 0% 0% no-repeat padding-box;
  border: 1px solid #333;
  color: #E2E2E2;
}
.smart-style-1 .blacktooltip:hover {
  background: #202124 0% 0% no-repeat padding-box !important;
}
.smart-style-1 .blacktooltip .insideDivUl li {
  border-bottom: 1px solid #333;
}
.smart-style-1 .blacktooltip .insideDivUl li:last-child {
  border-bottom: unset;
}
.smart-style-1 .optionSelect {
  background-color: #202124 !important;
  color: #FFFFFF !important;
}
.smart-style-1 .table {
  width: 100%;
  color: #ffffff;
  border-collapse: collapse;
  background-color: #666666;
  border-color: #333 !important;
  border-radius: 5px;
}
.smart-style-1 .subscriptions-page .table {
  border-color: #202124 !important;
}
.smart-style-1 .subscriptions-page .table thead tr {
  background: #202124 !important;
}
.smart-style-1 .subscriptions-page td {
  background-color: #202124;
}
.smart-style-1 .history-table th {
  border-color: #666666 !important;
  background-color: #404040 !important;
  color: #ffffff;
}
.smart-style-1 .commonHead {
  background-color: #202124 !important;
  color: #E2E2E2;
  font-size: 14px;
  border: unset !important;
}
.smart-style-1 .algntblTranscation {
  background: #202124 !important;
}
.smart-style-1 .historyLabel {
  color: #ffffff;
}
.smart-style-1 table.history-table {
  margin-top: 0px !important;
  border-radius: 8px !important;
}
.smart-style-1 table.history-table tr:hover {
  background-color: #404040 !important;
  color: #ffffff;
}
.smart-style-1 .history-table .history_action {
  text-decoration: underline !important;
}
.smart-style-1 .table-bordereds > tbody > tr > td {
  border: 1px solid #333;
  z-index: 10px;
}
.smart-style-1 .owl-dt-calendar-control {
  color: #ffffff !important;
}
.smart-style-1 .owl-dt-container {
  background: #202124 !important;
  border: #333 !important;
  border-radius: 10px !important;
}
.smart-style-1 .owl-dt-calendar-table .owl-dt-calendar-header {
  color: #666666 !important;
}
.smart-style-1 .owl-dt-calendar-table .owl-dt-calendar-cell {
  color: #666666 !important;
}
.smart-style-1 .owl-dt-calendar-table .owl-dt-calendar-cell-selected {
  background-color: #171819 !important;
  border: #333 !important;
}
.smart-style-1 .timeZoneBtn {
  color: #000 !important;
}
.smart-style-1 .gradientDiv {
  background-color: #171819 !important;
  border: 1px solid #333;
}
.smart-style-1 .gradientDiv:hover {
  background-color: #171819 !important;
}
.smart-style-1 .hoverBtn {
  background: #171819 !important;
}
.smart-style-1 .dataTables_processing {
  background: url('spin-loader_small_white.797d455d611e7389cd3b.svg') no-repeat !important;
}
.smart-style-1 table.pending-list-table {
  margin-top: 0px !important;
  border: 1px solid #333 !important;
  border-radius: 5px;
}
.smart-style-1 .table thead tr th {
  border-color: #333;
}
.smart-style-1 .table tbody tr td {
  border-color: #333;
}
.smart-style-1 .history-btn {
  color: #ffffff;
}
.smart-style-1 .history-btn:hover {
  background-color: var(--theme-color-1) !important;
  color: #000;
}
.smart-style-1 .list-area {
  background-color: #0D0F12;
  border-radius: 0px;
}
.smart-style-1 .serviceTheme {
  background-color: #171717 !important;
  border: 1px solid #333 !important;
}
.smart-style-1 .serviceTheme .ui-dialog-titlebar {
  background-color: #171717 !important;
  border-bottom: none !important;
  width: 100% !important;
}
.smart-style-1 .serviceTheme .ui-dialog-titlebar .ui-dialog-title {
  width: 100% !important;
}
.smart-style-1 .serviceTheme .ui-dialog-titlebar .ui-dialog-title .widget-header {
  display: flex;
  justify-content: normal;
}
.smart-style-1 .serviceTheme .ui-dialog-titlebar .ui-dialog-title .shortDescription {
  text-align: center;
}
.smart-style-1 .serviceTheme .ui-dialog-titlebar .ui-dialog-titlebar-close {
  position: absolute;
  top: 25px;
  right: 5px;
}
.smart-style-1 .serviceTheme .ui-dialog-titlebar h5 {
  color: #ffffff !important;
}
.smart-style-1 .serviceTheme .ui-dialog-titlebar h1 {
  color: #ffffff !important;
}
.smart-style-1 .serviceTheme .ui-dialog-titlebar .ui-button {
  color: var(--theme-color-1);
  opacity: 1;
}
.smart-style-1 .serviceTheme .ui-dialog-content {
  background-color: #171717 !important;
}
.smart-style-1 .serviceTheme .ui-dialog-content .service_modal {
  background-color: #171717 !important;
}
.smart-style-1 .serviceTheme .ui-dialog-content .service_modal .modal-content {
  box-shadow: unset !important;
  -webkit-box-shadow: unset !important;
  border: unset !important;
  background-color: #171717 !important;
}
.smart-style-1 .serviceTheme .chip_Section {
  border: 1px solid #333 !important;
  background-color: #7c7b7b !important;
  color: #ffffff !important;
  margin-left: 0px !important;
}
.smart-style-1 .serviceTheme .toggleMore {
  background-color: #707070 !important;
}
.smart-style-1 .serviceTheme .serachService {
  background-color: #dadce0 !important;
}
.smart-style-1 .dropdown-list.fixMultiSelect {
  background-color: #171717 !important;
  box-shadow: 0 1px 5px #171717 !important;
  border: 1px solid #333 !important;
}
.smart-style-1 .dropdown-list.fixMultiSelect .filter-textbox {
  border-bottom: 1px solid #333 !important;
}
.smart-style-1 .dropdown-list.fixMultiSelect .item1 .multiselect-item-checkbox {
  border-bottom: none !important;
}
.smart-style-1 .user-role-form .multiselect-item-checkbox input[type=checkbox] + div {
  color: #ffffff !important;
}
.smart-style-1 .user-role-form .item1 .multiselect-item-checkbox:hover {
  background-color: #7c7b7b !important;
}
.smart-style-1 .user-role-form .item1 .multiselect-item-checkbox:hover input[type=checkbox] + div {
  color: #111 !important;
}
.smart-style-1 .user-role-form .item2 .multiselect-item-checkbox:hover {
  background-color: #7c7b7b !important;
}
.smart-style-1 .user-role-form .multiselect-dropdown .dropdown-btn {
  border: unset !important;
  padding: 6px 0px !important;
}
.smart-style-1 .price_calcultation_form .dropdown-list {
  background-color: #202124 !important;
  box-shadow: 0 1px 5px #171717 !important;
  border: 1px solid #333 !important;
}
.smart-style-1 .price_calcultation_form .multiselect-item-checkbox input[type=checkbox] + div {
  color: #ffffff !important;
}
.smart-style-1 .price_calcultation_form .item1 .multiselect-item-checkbox:hover {
  background-color: #7c7b7b !important;
}
.smart-style-1 .price_calcultation_form .item1 .multiselect-item-checkbox:hover input[type=checkbox] + div {
  color: #111 !important;
}
.smart-style-1 .price_calcultation_form .item2 .multiselect-item-checkbox:hover {
  background-color: #7c7b7b !important;
}
.smart-style-1 .price_calcultation_form .multiselect-dropdown .dropdown-btn {
  border: 1px solid #333 !important;
}
.smart-style-1 .reMark {
  color: #ffffff;
  border: 1px solid #333;
}
.smart-style-1 .spin-loader {
  background: url('spin-loader_small_white.797d455d611e7389cd3b.svg') no-repeat !important;
}
.smart-style-1 .big-spin-loader {
  background: url('spin-loader_big_white.3c7bb61bb066252fe699.svg') no-repeat !important;
}
.smart-style-1 .btnModalSave {
  float: right;
  background-color: #202124;
  border: none;
  color: #ffffff !important;
  border-radius: 15px;
  border: 2px solid #333;
}
.smart-style-1 .btnModalSave:hover {
  color: #000 !important;
}
.smart-style-1 .btnModalClose {
  float: left;
  background-color: #202124;
  border: none;
  color: #ffffff !important;
  border-radius: 15px;
  border: 2px solid #333 !important;
}
.smart-style-1 .btnModalClose:hover {
  color: #000 !important;
}
.smart-style-1 .successDiv {
  background-color: #171819;
}
.smart-style-1 .successinviteDiv {
  background-color: #171819;
}
.smart-style-1 .successappDiv {
  background-color: #171819;
}
.smart-style-1 .successcmpimgDiv {
  background-color: #171819;
}
.smart-style-1 .successcmpprofileimgDiv {
  background-color: #171819;
}
.smart-style-1 .successuserdeleteDiv {
  background-color: #171819;
}
.smart-style-1 .disabledColor {
  background-color: #404040 !important;
  color: #666666 !important;
  border-color: #666666 !important;
}
.smart-style-1 .inv-img {
  content: url('invoice_orange.449323bf104736ae3fe4.svg') !important;
}
.smart-style-1 .badge-disable-iom {
  color: #ffffff !important;
}
.smart-style-1 .checkmark {
  border: 2px solid #333;
}
.smart-style-1 .checkcontractsmark {
  border: 2px solid #fff;
}
.smart-style-1 .checkbox-container .checkmark:after {
  left: 7px;
  top: 1px;
  width: 8px;
  height: 16px;
  border: solid #333;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}
.smart-style-1 .checkbox-container .checkcontractsmark:after {
  left: 7px;
  top: 1px;
  width: 8px;
  height: 16px;
  border: solid #333;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}
.smart-style-1 .vertical-line {
  background-color: #666666;
}
.smart-style-1 .card_details_nocard p {
  color: #ffffff;
  font-size: 14px;
}
.smart-style-1 .subPlanData {
  color: #ffffff;
}
.smart-style-1 .table-wrapper {
  background-color: #404040 !important;
}
.smart-style-1 .table-wrapper table tbody tr td {
  background-color: #202124 !important;
}
.smart-style-1 .tooltipRole:hover .tooltiptextRole {
  border: 1px solid #333;
  color: #E2E2E2;
}
.smart-style-1 .tooltipRole:hover .tooltiptextRole:hover {
  background: #202124 0% 0% no-repeat padding-box !important;
}
.smart-style-1 .tooltipRole:hover .tooltiptextRole .insideDivUl li {
  border-bottom: 1px solid #333;
}
.smart-style-1 .tooltipRole:hover .tooltiptextRole .insideDivUl li:last-child {
  border-bottom: unset;
}
.smart-style-1 .tooltipRole .tooltiptextRole {
  background-color: #202124;
  color: #ffffff;
}
.smart-style-1 .cardconfirmLabel {
  color: #E2E2E2 !important;
  border: 1px solid #333;
}
.smart-style-1 .cardconfirmLabel .header-formbtn {
  background-color: unset !important;
  border: 2px solid #333 !important;
  color: #ffffff !important;
  padding: 3px 15px;
}
.smart-style-1 .cardconfirmLabel .header-formbtn:hover {
  background: var(--theme-color-1) !important;
  color: #202528 !important;
}
.smart-style-1 .MessageBoxButtonSection button {
  color: #ffffff;
}
.smart-style-1 .manageHeader, .smart-style-1 .comapnyHeaderLbl {
  color: #ffffff !important;
}
.smart-style-1 .top-div {
  border-bottom: 1px solid #333 !important;
}
.smart-style-1 .top-div .description-div .description-content {
  border: 1px solid #333 !important;
}
.smart-style-1 .step-div {
  background: #202124;
}
.smart-style-1 .footer-div {
  background: #202124;
}
.smart-style-1 .fieldForm .form-control {
  background-color: #202124;
  border: 1px solid #333;
  color: #ffffff;
}
.smart-style-1 .algntblTranscation {
  border-radius: 20px;
  border: 1px solid #333 !important;
  overflow: hidden;
  background: #202124 !important;
}
.smart-style-1 .setselectHeight {
  background-color: #202124;
  color: #ffffff !important;
  border-color: #666666;
}
.smart-style-1 .backtopagewrapper {
  color: #ffffff;
}
.smart-style-1 .successdeleteDiv {
  background-color: #171819;
}
.smart-style-1 .btnModalClose {
  float: left;
  background-color: #202124;
  border: none;
  border-radius: 15px;
  border: 2px solid var(--theme-color-1) !important;
}
.smart-style-1 .search_status {
  background-color: #202124 !important;
  color: #ffffff;
}
.smart-style-1 .drop {
  color: #fff !important;
}
.smart-style-1 .ui-datepicker {
  background: #202124 !important;
  border-color: #666666 !important;
  z-index: 2000 !important;
}
.smart-style-1 .ui-datepicker .ui-datepicker-header {
  background-color: #202124;
}
.smart-style-1 .ui-datepicker select.ui-datepicker-month {
  background: #202124 !important;
}
.smart-style-1 .ui-datepicker select.ui-datepicker-year {
  background: #202124 !important;
}
.smart-style-1 .ui-datepicker th {
  color: #ffffff !important;
}
.smart-style-1 .ui-datepicker td .ui-state-active {
  background: #707070;
}
.smart-style-1 .ui-datepicker-header a {
  color: #ffffff !important;
}
.smart-style-1 .ui-datepicker-month {
  color: #ffffff !important;
}
.smart-style-1 .ui-datepicker-year {
  color: #ffffff !important;
}
.smart-style-1 .ui-datepicker-prev:before {
  background: #202124 !important;
}
.smart-style-1 .ui-datepicker-next:before {
  background: #202124 !important;
}
.smart-style-1 .ui-datepicker td .ui-state-default {
  color: #ffffff;
}
.smart-style-1 .setCircleactive {
  color: #00eba9;
  font-size: 10px !important;
}
.smart-style-1 .iconForm {
  color: #ffffff;
}
.smart-style-1 .nav_btn {
  color: #ffffff !important;
}
.smart-style-1 #extr-page {
  background: #202124 !important;
}
.smart-style-1 .border-style {
  border-color: #333333;
}
.smart-style-1 .dropzonbox {
  background-color: #202124 !important;
  border: 1px solid #333 !important;
}
.smart-style-2 .user-role-form .multiselect-dropdown .dropdown-btn {
  border: unset !important;
}
.smart-style-2 .user-role-form .multiselect-dropdown .dropdown-btn .selected-item-container .selected-item {
  border: 1px solid var(--theme-color-1) !important;
  background: var(--theme-color-1) !important;
  color: #ffffff !important;
  max-width: unset !important;
  padding: 3px 10px !important;
  border-radius: 20px !important;
  margin-right: 10px !important;
}
.smart-style-2 .user-role-form .multiselect-dropdown .dropdown-btn .selected-item-container .selected-item a {
  color: #ffffff !important;
}
.smart-style-2 .setCircleactive {
  color: #00eba9;
  font-size: 10px !important;
}
.smart-style-2 #main {
  background-color: #ffffff !important;
  height: 100% !important;
}
.smart-style-2 .theme-header-text-color {
  color: #212529 !important;
}
.smart-style-2 .theme-header-color {
  color: #4C4F53 !important;
}
.smart-style-2 .cancel {
  text-align: left;
  width: auto;
  font-size: 13px;
  border-radius: 15px;
  background: #ffffff 0% 0% no-repeat padding-box !important;
  border: 2px solid var(--theme-color-1) !important;
  letter-spacing: 0px;
  color: #171717 !important;
  padding: 3px 15px;
}
.smart-style-2 .TotalLabel {
  top: 275px;
  left: 560px;
  height: 15px;
  text-align: left;
  font: normal normal bold 31px/23px Open Sans;
  letter-spacing: 0px;
  color: #202528;
  opacity: 1;
}
.smart-style-2 .paymentSectionDetails {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
  background-color: #ffffff !important;
}
.smart-style-2 .paymentSectionDetails {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
  background-color: #F7F7F7 !important;
  border-radius: 10px;
  margin-left: -1px;
}
.smart-style-2 .paymentOptions {
  display: none;
  border-radius: 10px;
}
.smart-style-2 .currentButton {
  background-color: #F7F7F7 !important;
  color: #000 !important;
  border-color: #CCCCCC !important;
  cursor: not-allowed;
  z-index: 1;
}
.smart-style-2 .currentPlan {
  background-color: #CCCCCC !important;
  color: #202528 !important;
}
.smart-style-2 .currentPlan:hover {
  background: #CCCCCC !important;
}
.smart-style-2 .emptyCol {
  border-top-right-radius: 0px !important;
  border-top: 1px solid transparent !important;
  border-left: 1px solid transparent !important;
  border-right: 1px solid transparent !important;
  border-bottom: 1px solid transparent !important;
}
.smart-style-2 .selectButton {
  border-radius: 0%;
  padding: 3px 15px;
  background-color: #ffffff;
  border: 2px solid var(--theme-color-1);
  border-radius: 20px;
  color: #202528;
  font-size: 14px;
  outline: none;
}
.smart-style-2 .selectButton:hover {
  background: var(--theme-color-1) !important;
  color: var(--hovertext-color) !important;
  border-color: var(--theme-color-1);
}
.smart-style-2 .nextBtn {
  font-size: 13px;
  background: #ffffff 0% 0% no-repeat padding-box !important;
  border-color: var(--theme-color-1) !important;
  letter-spacing: 0px;
  border: 2px solid var(--theme-color-1) !important;
  border-radius: 15px;
  color: #171717 !important;
  padding: 3px 15px;
}
.smart-style-2 .nextBtn:hover {
  background: var(--theme-color-1) !important;
  border: 2px solid var(--theme-color-1);
  color: var(--hovertext-color) !important;
}
.smart-style-2 .cancelBtn {
  border: 2px solid var(--theme-color-1) !important;
  border-radius: 15px;
  background: #F7F7F7 0% 0% no-repeat padding-box !important;
  color: #000 !important;
  font-size: 13px;
  padding: 3px 15px !important;
}
.smart-style-2 .cancelBtn:hover {
  background: var(--theme-color-1) !important;
  border: 2px solid var(--theme-color-1);
  color: var(--hovertext-color) !important;
}
.smart-style-2 .jarviswidget > div {
  border: none;
}
.smart-style-2 .pricingTable table {
  color: #000 !important;
}
.smart-style-2 .bootstrapWizard li .step {
  background-color: #ffffff;
  border: 1px solid #CCCCCC;
  color: #202528;
}
.smart-style-2 .lightBackgroundColor {
  background-color: #ffffff !important;
  border: 1px solid #CCCCCC !important;
  border-radius: 10px;
  margin-left: 13px;
}
.smart-style-2 .left-div {
  background-color: #ffffff !important;
  border-radius: 10px;
}
.smart-style-2 .card-div {
  border-radius: 5px;
  background-color: #ffffff !important;
  border: 1px solid #CCCCCC !important;
  padding-bottom: 0px;
}
.smart-style-2 .card-div:hover {
  background-color: #EEEEEE !important;
}
.smart-style-2 .textColor {
  color: #202528 !important;
}
.smart-style-2 .textColor:hover {
  background: transparent !important;
}
.smart-style-2 .headData {
  background-color: #ffffff;
  color: #000;
}
.smart-style-2 .jarviswidget-color-darken header {
  background: none !important;
  color: #4C4F53 !important;
}
.smart-style-2 .jarviswidget > header {
  color: #333;
  border: none;
}
.smart-style-2 .back-btn {
  background: #073E84 0% 0% no-repeat padding-box;
  border-color: #073E84;
  font-size: 13px;
}
.smart-style-2 .applicationHead {
  background: #ffffff 0% 0% no-repeat padding-box;
  color: black !important;
}
.smart-style-2 #left-panel {
  padding-right: 0px !important;
  padding-left: 0px !important;
  padding-bottom: 0px !important;
  padding-top: 10px !important;
}
.smart-style-2 #left-panel nav ul li {
  border-bottom: 1px solid var(--theme-color-1);
}
.smart-style-2 #left-panel nav ul li:hover {
  background: var(--theme-color-1) !important;
  color: var(--hovertext-color) !important;
  border: 1px solid #3B3B3B33;
}
.smart-style-2 #left-panel nav ul li a:hover {
  color: var(--hovertext-color) !important;
}
.smart-style-2 #left-panel nav ul li.active {
  background-color: var(--theme-color-1) !important;
}
.smart-style-2 #left-panel nav ul li.active a:before {
  content: unset !important;
}
.smart-style-2 nav ul li.active a:before {
  color: #202427;
}
.smart-style-2 nav ul li a {
  color: #4c4f53 !important;
  padding: 12px 17px 12px 16px !important;
  font-size: 16px !important;
}
.smart-style-2 nav ul li a i {
  font-size: 17px !important;
  vertical-align: 0 !important;
}
.smart-style-2 .nav_btn {
  color: #4c4f53 !important;
}
.smart-style-2 nav ul li.active > a:before {
  content: unset !important;
}
.smart-style-2 .notification-body .from {
  color: #4C4F53 !important;
}
.smart-style-2 .notification-body .msg-body {
  color: #7A7A7A !important;
}
.smart-style-2 .notification-body .activityTime {
  color: #7A7A7A !important;
}
.smart-style-2 .notification-body li span {
  background-color: #F7F7F7 !important;
}
.smart-style-2 .ajax-dropdown {
  border: 1px solid #d9d9d9 !important;
}
.smart-style-2 #fullscreen .svg-icon {
  background-image: url('Path222.faed6ac4229dbddce176.svg');
}
.smart-style-2 #header .notification-svg {
  background-image: url('39-Notification.a91267442bae57b87b90.svg');
}
.smart-style-2 #header #activity.activity-svg {
  background-image: url('Page-1.06157606d3196914b46c.svg');
}
.smart-style-2 #header .collapseMenu .collaspe-menu-svg {
  background-image: url('Path 224.55a5f2da498c377ed45f.svg');
}
.smart-style-2 #header .collapseMenu .collaspe-menu-sub-svg {
  background-image: url('Group224.649ca2f656deeb39e0a7.svg');
  cursor: pointer;
}
.smart-style-2 #header .collapseMenu:hover {
  background-color: #CCCCCC;
}
.smart-style-2 #header .header-btn {
  box-shadow: none;
}
.smart-style-2 #header .header-btn-list a {
  box-shadow: none;
}
.smart-style-2 #header .menu-item:not(:last-child) {
  border: unset !important;
}
.smart-style-2 #header .menu-item .webComponetsBorder {
  border: none;
}
.smart-style-2 #header .menu-item:hover {
  background-color: #CCCCCC;
}
.smart-style-2 #header .menu-item .webComponetsHoverColor:hover {
  background-color: #CCCCCC;
}
.smart-style-2 #header .menu-item.no-hover:hover {
  background-color: #EDEDED;
}
.smart-style-2 .header-dropdown-list a.dropdown-toggle {
  color: #4C4F53 !important;
}
.smart-style-2 .header-dropdown-list .dropdown-menu li a:hover {
  background-color: #EDEDED !important;
}
.smart-style-2 .header-dropdown-list .dropdown-menu .active a {
  background-color: #EDEDED !important;
}
.smart-style-2 .theme-reverse-text-color {
  color: #0C1214 !important;
  color: #ffffff !important;
}
.smart-style-2 .theme-icon {
  color: #4C4F53 !important;
}
.smart-style-2 .theme-text-color {
  color: #4C4F53 !important;
}
.smart-style-2 .theme-header-text-color {
  color: #4C4F53 !important;
}
.smart-style-2 .theme-subtext-color {
  color: #7A7A7A !important;
}
.smart-style-2 .dashboard .jarviswidget div {
  background-color: #F7F7F7 !important;
  border: 1px solid #CCCCCC !important;
}
.smart-style-2 .profileBackground {
  background-color: #F7F7F7 !important;
  border: 1px solid #CCCCCC !important;
}
.smart-style-2 #ribbon .breadcrumb li {
  color: #69737A;
}
.smart-style-2 #ribbon .breadcrumb li:last-child {
  color: #4C4F53 !important;
}
.smart-style-2 .userProfile .applicationBackGround {
  background-color: #ffffff !important;
}
.smart-style-2 .userProfile .theme-text-color {
  color: #202528 !important;
}
.smart-style-2 .border {
  border: 1px solid #ced4da !important;
  border-radius: 10px;
}
.smart-style-2 .subList:not(:last-child) {
  border-bottom: 1px solid #ffffff !important;
}
.smart-style-2 .theme-fade-color {
  color: #7A7A7A !important;
}
.smart-style-2 .applicationHeadModal {
  background: #353D4B !important;
}
.smart-style-2 .modalBodyBgColor {
  background-color: #F7F7F7 !important;
}
.smart-style-2 .spanBoderBottom {
  border-bottom: 1px solid #CCCCCC !important;
}
.smart-style-2 .theme-bg-color {
  background-color: #F7F7F7 !important;
}
.smart-style-2 .theme-header-color {
  color: #000 !important;
}
.smart-style-2 .footerTextColor {
  color: #ffffff !important;
}
.smart-style-2 .login-info {
  color: #212529 !important;
}
.smart-style-2 #content {
  background: #ffffff !important;
}
.smart-style-2 .auth-text-color {
  color: #CCCCCC !important;
}
.smart-style-2 .menuIconsOption img {
  filter: brightness(100) invert(1);
}
.smart-style-2 .menuSelected {
  background-color: #CCCCCC;
}
.smart-style-2 .account-table-div .dt-toolbar-footer {
  border: 1px solid #EDEDED !important;
  margin-top: -6px;
  box-shadow: none !important;
  background-color: #EDEDED !important;
  width: 100% !important;
}
.smart-style-2 .account-table-div .dt-toolbar-footer .pagination .paginate_button.active a {
  background-color: #4C4F53 !important;
  border-color: #4C4F53 !important;
  color: #F7F7F7 !important;
}
.smart-style-2 .account-table .history_action {
  text-decoration: underline !important;
}
.smart-style-2 .account-table td {
  color: #4C4F53 !important;
  padding-left: 24px !important;
}
.smart-style-2 .account-table th {
  color: #4C4F53 !important;
  padding-left: 24px !important;
}
.smart-style-2 nav ul li.active > a:before {
  content: unset !important;
}
.smart-style-2 .dropdown-icon-menu > ul > li .btn {
  background: none;
}
.smart-style-2 .header-btn {
  background: none;
}
.smart-style-2 .body-image {
  border-radius: 100%;
}
.smart-style-2 .profileDeatils {
  border: 1px solid #CCCCCC;
  margin-top: -42px;
  background-color: #666666;
  border-radius: 10px;
  height: 601px;
}
.smart-style-2 .theme-text-color {
  color: #202528;
}
.smart-style-2 .jarviswidget > div {
  border-right-color: #ffffff !important;
  border-bottom-color: #ffffff !important;
  border-left-color: #ffffff !important;
}
.smart-style-2 .lstTrncnTbl .dt-toolbar {
  background: none !important;
  border-bottom: none;
}
.smart-style-2 .lstTrncnTbl td a {
  color: #212529;
}
.smart-style-2 .company_details_section {
  border: 1px solid #ced4da !important;
}
.smart-style-2 .contact_infrmtn_sctn {
  border: 1px solid #CCCCCC !important;
}
.smart-style-2 .contact_infrmtn_sctn .detailLbl {
  color: #000;
  font-size: 14px;
}
.smart-style-2 .contact_infrmtn_sctn .detailLbl .commoninputStyle .form-control {
  height: 40px !important;
  border-radius: 6px !important;
  background-color: #EEEEEE;
  border: 1px solid #EEEEEE;
  font-size: 14px;
  color: #000;
}
.smart-style-2 .comapny_details_form .detailLbl {
  color: #000;
  font-size: 14px;
}
.smart-style-2 .comapny_details_form .detailLbl .commoninputStyle .form-control {
  height: 40px !important;
  border-radius: 6px !important;
  background-color: transparent !important;
  border: 1px solid #ced4da;
  font-size: 14px;
  color: #000;
}
.smart-style-2 .company_detail_head {
  color: #000 !important;
}
.smart-style-2 .editEnabled .detailLbl {
  color: #000 !important;
  font-size: 14px !important;
}
.smart-style-2 .editEnabled .detailLbl .commoninputStyle .form-control {
  background-color: #ffffff !important;
}
.smart-style-2 .detail_save_btn,
.smart-style-2 .detail_save_Btn,
.smart-style-2 .approveBtn,
.smart-style-2 .detailInfo_save_btn,
.smart-style-2 .approveBtn,
.smart-style-2 .iom_btn {
  color: #000;
}
.smart-style-2 .detail_save_btn:hover,
.smart-style-2 .detail_save_Btn:hover,
.smart-style-2 .approveBtn:hover,
.smart-style-2 .detailInfo_save_btn:hover,
.smart-style-2 .approveBtn:hover,
.smart-style-2 .iom_btn:hover {
  color: var(--hovertext-color) !important;
}
.smart-style-2 .editDisabled {
  background: none;
}
.smart-style-2 .editEnabled {
  background-color: #F7F7F7 !important;
}
.smart-style-2 .profile_details .prf_name {
  color: #000;
}
.smart-style-2 #left-panel b.collapse-sign {
  display: none;
}
.smart-style-2 .theme-content-color {
  color: #666666;
}
.smart-style-2 .addressdiv {
  background: #EEEEEE 0% 0% no-repeat padding-box !important;
}
.smart-style-2 .theme-button-color {
  background-color: #ffffff;
  color: #000;
}
.smart-style-2 .tranhoverTable select {
  background-color: inherit;
  color: inherit;
  border: 1px solid #CCCCCC;
  border-radius: 5px;
}
.smart-style-2 .tranhoverTable select option {
  background: #EEEEEE 0% 0% no-repeat padding-box;
  color: #202528;
}
.smart-style-2 .tranhoverTable input[type=radio]:checked:after {
  border: 2px solid #CCCCCC;
}
.smart-style-2 .butttonBluecolor {
  background-color: #ffffff !important;
  border: 2px solid var(--theme-color-1) !important;
  color: #171717 !important;
  border-radius: 20px;
  padding: 3px 15px;
}
.smart-style-2 .butttonBluecolor:hover {
  background: var(--theme-color-1) !important;
  border: 2px solid var(--theme-color-1);
  color: var(--hovertext-color) !important;
}
.smart-style-2 .gridSectionFirst .payMethodSection {
  border: 1px solid #CCCCCC;
}
.smart-style-2 .gridSectionFirst .payMethodSection .payHead span {
  color: #000;
  font-weight: 400;
}
.smart-style-2 .gridSectionFirst .techContactSection {
  border: 1px solid #CCCCCC;
}
.smart-style-2 .gridSectionFirst .techContactSection .techHead {
  color: #000;
  font-weight: 400;
}
.smart-style-2 .technical_details_form .detailLbl {
  color: #000;
  font-size: 14px;
}
.smart-style-2 .technical_details_form .detailLbl .commoninputStyle .form-control {
  height: 40px !important;
  border-radius: 6px !important;
  background-color: transparent;
  border: 1px solid #EEEEEE;
  font-size: 14px;
  color: #000;
}
.smart-style-2 .gridSectionTwo .billingContactSection {
  border: 1px solid #CCCCCC;
}
.smart-style-2 .gridSectionTwo .billingContactSection .billHead {
  font-size: 16px;
  color: #000;
  font-weight: 400;
}
.smart-style-2 .billing_details_form .detailLbl {
  color: #000;
  font-size: 14px;
}
.smart-style-2 .billing_details_form .detailLbl .commoninputStyle .form-control {
  height: 40px !important;
  border-radius: 6px !important;
  background-color: transparent !important;
  border: 1px solid #EEEEEE;
  font-size: 14px;
  color: #000;
}
.smart-style-2 .gridSectionThree .estimatedBillSection {
  padding: 10px 30px;
  border: 1px solid #CCCCCC;
  margin: 20px 0px 20px 20px;
  width: 96%;
  border-radius: 10px;
  height: 584px;
}
.smart-style-2 .gridSectionThree .estimatedBillSection hr {
  border-top: 1px solid #CCCCCC !important;
}
.smart-style-2 .gridSectionThree .estimatedBillSection .estBillHead {
  font-size: 16px;
  color: #000;
  font-weight: 400;
}
.smart-style-2 .gridSectionThree .estimatedBillSection .billItemDesc {
  font-size: 14px;
  color: #000;
}
.smart-style-2 .gridSectionThree .estimatedBillSection .billItemPrice {
  font-size: 14px;
  color: #000;
}
.smart-style-2 .cardNum,
.smart-style-2 .cardOwnerName,
.smart-style-2 .cardExp_Date {
  color: #000 !important;
}
.smart-style-2 .editPaymentHeader {
  color: #000 !important;
  font-size: 16px !important;
}
.smart-style-2 .payContent {
  background: #F7F7F7 !important;
}
.smart-style-2 .edit_details_form .detailLbl {
  color: #000;
  font-size: 14px;
}
.smart-style-2 .edit_details_form .detailLbl .commoninputStyle .form-control {
  height: 40px !important;
  border-radius: 6px !important;
  background-color: #ffffff;
  border: 1px solid #CCCCCC;
  font-size: 14px;
  color: #000;
}
.smart-style-2 .modal-content {
  background-color: #ffffff !important;
  border: 1px solid #666666;
  color: #171717;
  border-radius: 10px;
}
.smart-style-2 .underline {
  border-color: #CCCCCC;
}
.smart-style-2 .subscription-sum {
  border-top: 2px solid #CCCCCC !important;
}
.smart-style-2 .card-fields .form-fields-wrapper {
  border-bottom: 1px solid #d5e1ec;
}
.smart-style-2 .card-fields .form-fields-wrapper .icon {
  width: 25px;
  height: auto;
}
.smart-style-2 .card-fields .form-fields-wrapper .form-fields {
  width: calc(100% - 35px);
  margin-left: auto;
}
.smart-style-2 .card-fields .form-fields-wrapper .form-fields input {
  outline: none;
  border: none;
  width: 100%;
  line-height: 50px;
  padding: 0;
  font-weight: 500;
  font-size: 20px;
  background-color: #F7F7F7;
}
.smart-style-2 .card-fields .form-fields-wrapper .form-fields input::-webkit-input-placeholder {
  color: #333;
  font-size: 16px;
}
.smart-style-2 .card-fields .form-fields-wrapper .form-fields input::-moz-placeholder {
  color: #333;
  font-size: 16px;
}
.smart-style-2 .card-fields .form-fields-wrapper .form-fields input:-ms-input-placeholder {
  color: #333;
  font-size: 16px;
}
.smart-style-2 .card-fields .form-fields-wrapper .form-fields input :-moz-placeholder {
  color: #333;
  font-size: 16px;
}
.smart-style-2 .card-fields .checkbox {
  color: #005397;
}
.smart-style-2 .card-fields .card-errors {
  color: #eb0042;
  font-size: 13px;
  margin: 10px 0 0 0;
}
.smart-style-2 .payment-detail {
  width: 100%;
  max-width: 1010px;
  background-color: #F7F7F7;
  margin: auto;
  padding: 10px 10px 6px 10px;
}
.smart-style-2 .nav ul .active > a {
  color: #202427 !important;
  font-weight: 700;
}
.smart-style-2 .ElementsApp,
.smart-style-2 .ElementsApp .InputElement {
  color: #000 !important;
  font-weight: 300;
  font-family: "Helvetica Neue", Helvetica, sans-serif;
  font-size: 18px;
}
.smart-style-2 .prf_name,
.smart-style-2 .prf_url {
  color: #000 !important;
}
.smart-style-2 .sub-btn {
  border: 2px solid var(--theme-color-1) !important;
  color: #171717 !important;
}
.smart-style-2 .sub-btn:hover {
  background-color: var(--theme-color-1) !important;
  color: var(--hovertext-color) !important;
}
.smart-style-2 .manage-btn {
  font-size: 13px;
  border: 2px solid #CCCCCC !important;
  border-radius: 20px;
  background-color: #ffffff !important;
  color: #171717 !important;
  padding: 3px 15px;
}
.smart-style-2 .manage-btn:hover {
  background: var(--theme-color-1) !important;
  color: var(--hovertext-color) !important;
  border: 2px solid var(--theme-color-1) !important;
}
.smart-style-2 .ganerateButton {
  background: #ffffff;
  color: #000;
}
.smart-style-2 .stepsChecked {
  font-weight: 900;
  background-color: #333;
}
.smart-style-2 .next_payment {
  background-color: #CCCCCC;
  color: #000;
}
.smart-style-2 .selectExpiry .form-control {
  height: 40px !important;
  border-radius: 6px !important;
  background-color: #ffffff;
  border: 1px solid #CCCCCC;
  font-size: 14px;
  color: #000;
  margin-top: 5px;
}
.smart-style-2 .form-control.is-invalid {
  background-image: none !important;
  border-color: #dc3545 !important;
}
.smart-style-2 label {
  font-weight: 400;
}
.smart-style-2 .profileEditTextBox {
  background: #ffffff 0% 0% no-repeat padding-box !important;
}
.smart-style-2 .profileModalBtn {
  padding: 10px;
  background-color: #ffffff !important;
  border: 2px solid var(--theme-color-1) !important;
  color: #000 !important;
  border-radius: 19px;
}
.smart-style-2 .profileModalBtn:hover {
  background: var(--theme-color-1) !important;
  color: var(--hovertext-color) !important;
}
.smart-style-2 .sub-img {
  background: #666666 0% 0% no-repeat padding-box;
}
.smart-style-2 .row-divider {
  border-color: #CCCCCC !important;
}
.smart-style-2 .dot-div {
  color: #666666 !important;
}
.smart-style-2 .theme-header-text-color {
  color: #212529 !important;
}
.smart-style-2 .theme-header-color {
  color: #4C4F53 !important;
}
.smart-style-2 .cancel {
  text-align: left;
  width: auto;
  font-size: 13px;
  border-radius: 15px;
  background: #ffffff 0% 0% no-repeat padding-box !important;
  border: 2px solid var(--theme-color-1) !important;
  letter-spacing: 0px;
  color: #171717 !important;
  padding: 3px 15px;
}
.smart-style-2 .TotalLabel {
  top: 275px;
  left: 560px;
  height: 15px;
  text-align: left;
  font: normal normal bold 31px/23px Open Sans;
  letter-spacing: 0px;
  color: #202528;
  opacity: 1;
}
.smart-style-2 .paymentSectionDetails {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
.smart-style-2 .paymentSectionDetails {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
  border-radius: 10px;
  margin-left: -1px;
}
.smart-style-2 .paymentOptions {
  display: none;
  border-radius: 10px;
}
.smart-style-2 .table-bordered > tbody > tr > td {
  border: 1px solid #CCCCCC;
}
.smart-style-2 .table-bordered > tbody.headData > tr > td.switchCol,
.smart-style-2 .table-bordered > thead.headData > tr > th.switchCol,
.smart-style-2 .table-bordered > tbody.table-headData > tr > td.switchCol {
  box-shadow: -1px 0 0 0 #CCCCCC;
}
.smart-style-2 .table-bordered > tbody.headData > tr > td:first-child.switchCol,
.smart-style-2 .table-bordered > tbody.table-headData > tr > td:first-child.switchCol {
  box-shadow: 0 -1px 0 0 #CCCCCC;
}
.smart-style-2 .currentPlan {
  background-color: #CCCCCC !important;
  color: #202528 !important;
}
.smart-style-2 .currentPlan:hover {
  background: #CCCCCC !important;
}
.smart-style-2 .emptyCol {
  background-color: #ffffff !important;
  border-top: 1px solid transparent !important;
  border-left: 1px solid transparent !important;
  border-right: 1px solid transparent !important;
  border-bottom: 1px solid transparent !important;
}
.smart-style-2 .nextBtn {
  font-size: 13px;
  background: #ffffff 0% 0% no-repeat padding-box !important;
  border-color: var(--theme-color-1) !important;
  letter-spacing: 0px;
  border: 2px solid var(--theme-color-1) !important;
  border-radius: 15px;
  color: #171717 !important;
  padding: 3px 15px;
}
.smart-style-2 .nextBtn:hover {
  background: var(--theme-color-1) !important;
  border: 2px solid var(--theme-color-1);
  color: var(--hovertext-color) !important;
}
.smart-style-2 .cancelBtn {
  border: 2px solid var(--theme-color-1) !important;
  border-radius: 15px;
  background: #F7F7F7 !important;
  color: #000 !important;
  font-size: 13px;
  padding: 3px 15px !important;
}
.smart-style-2 .cancelBtn:hover {
  background: var(--theme-color-1) !important;
  border: 2px solid var(--theme-color-1);
  color: var(--hovertext-color) !important;
}
.smart-style-2 .jarviswidget > div {
  border: none;
}
.smart-style-2 .pricingTable table {
  color: #000 !important;
}
.smart-style-2 .nextstep {
  font-size: 13px;
  background: #F7F7F7 0% 0% no-repeat padding-box !important;
  border-color: var(--theme-color-1) !important;
  letter-spacing: 0px;
  border: 2px solid var(--theme-color-1) !important;
  border-radius: 15px;
  color: #171717 !important;
  padding: 3px 15px;
}
.smart-style-2 .nextstep:hover {
  background: var(--theme-color-1) !important;
  border: 2px solid var(--theme-color-1);
  color: var(--hovertext-color) !important;
}
.smart-style-2 .bootstrapWizard li .step {
  background-color: #ffffff;
  border: 1px solid #CCCCCC;
  color: #202528;
}
.smart-style-2 .lightBackgroundColor {
  background-color: #ffffff !important;
  border: 1px solid #CCCCCC;
  border-radius: 10px;
  margin-left: 13px;
}
.smart-style-2 .textColor {
  color: #4C4F53 !important;
}
.smart-style-2 .NextconfirmLabel {
  color: #171717 !important;
  border: 1px solid #CCCCCC;
}
.smart-style-2 .cardconfirmLabel {
  color: #171717 !important;
  border: 1px solid #CCCCCC;
}
.smart-style-2 .back-btn {
  background: #073E84 0% 0% no-repeat padding-box;
  border-color: #073E84;
  font-size: 13px;
}
.smart-style-2 .buy-addon .navigationList .nav.nav-tabs li a span {
  color: #000 !important;
}
.smart-style-2 .buy-addon .navigationList .nav.nav-tabs li a:hover {
  background-color: var(--theme-color-1) !important;
  border: 1px solid var(--theme-color-1) !important;
}
.smart-style-2 .package-name .packageHeader {
  color: #202528 !important;
}
.smart-style-2 .yearlyData span.price {
  color: #202528 !important;
}
.smart-style-2 .buy-addon .checkout {
  background-color: #ffffff;
}
.smart-style-2 .table-headData {
  background-color: #ffffff;
  color: #000;
  margin-top: 10px;
}
.smart-style-2 .cart-btn {
  background-color: #202528;
}
.smart-style-2 .blacktooltip {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #CCCCCC;
  color: #4C4F53;
}
.smart-style-2 .blacktooltip .insideDivUl li {
  border-bottom: 1px solid #EDEDED;
}
.smart-style-2 .blacktooltip .insideDivUl li:last-child {
  border-bottom: unset;
}
.smart-style-2 .optionSelect {
  background-color: #FFFFFF !important;
  color: #202124 !important;
  border-color: #CCCCCC !important;
}
.smart-style-2 .table {
  width: 100%;
  color: #000;
  border-collapse: collapse;
  border-color: #CCCCCC;
  border-radius: 5px;
}
.smart-style-2 .history-table th {
  border-color: #CCCCCC !important;
  background: #EEEEEE 0% 0% no-repeat padding-box;
  color: #000;
}
.smart-style-2 .commonHead {
  background-color: #EEEEEE !important;
  color: #E2E2E2;
  font-size: 14px;
  border: unset !important;
}
.smart-style-2 .algntblTranscation {
  background: #ffffff !important;
}
.smart-style-2 .historyLabel {
  color: #000;
}
.smart-style-2 table.history-table {
  margin-top: 0 !important;
  border-radius: 5px !important;
  background-color: #ffffff;
}
.smart-style-2 table.history-table tr:hover {
  background-color: #EEEEEE !important;
  color: #000;
}
.smart-style-2 .history-table .history_action {
  text-decoration: underline !important;
}
.smart-style-2 .table-bordereds > tbody > tr > td {
  border: 1px solid #CCCCCC;
}
.smart-style-2 #userChoiceSelect .container,
.smart-style-2 #userChoiceSelect .durationDetailFields,
.smart-style-2 #userChoiceSelect .successNote {
  background-color: #EEE !important;
}
.smart-style-2 #userChoiceSelect .commonTimeData {
  background-color: #ffffff !important;
  border: 1px solid #EEEEEE !important;
}
.smart-style-2 #userChoiceSelect .fieldHeader {
  color: #000 !important;
}
.smart-style-2 #userChoiceSelect .commonFormField input,
.smart-style-2 #userChoiceSelect .commonFormField textarea {
  background-color: #ffffff !important;
  border: 1px solid #EEEEEE;
  color: #000 !important;
}
.smart-style-2 #userChoiceSelect textarea {
  background-color: #ffffff !important;
  border: 1px solid #EEEEEE;
  color: #000 !important;
}
.smart-style-2 .yearlabel {
  border: 1px solid #CCCCCC !important;
  background-color: #ffffff;
}
.smart-style-2 .cancellabel {
  border: 1px solid #CCCCCC !important;
  background-color: #ffffff;
}
.smart-style-2 .table-striped tbody tr:nth-of-type(odd) {
  background-color: #ffffff;
}
.smart-style-2 .gradientDiv {
  background: #EEEEEE 0% 0% no-repeat padding-box !important;
  border: 1px solid #CCCCCC66;
}
.smart-style-2 .hoverBtn {
  background: #ffffff !important;
}
.smart-style-2 .spnFilePath b {
  color: #ffffff !important;
}
.smart-style-2 .dataTables_processing {
  background: url('spin-loader.a01fb811724c25c2dd1e.svg') no-repeat !important;
}
.smart-style-2 .pagination > .active > a {
  background-color: var(--theme-color-1) !important;
  border-color: var(--theme-color-1) !important;
  color: #000;
}
.smart-style-2 .pagination > li > a {
  background-color: #ffffff !important;
  color: #000;
}
.smart-style-2 .pagination > li:first-child > a,
.smart-style-2 .pagination > li:first-child > span {
  margin-left: 0;
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
  background-color: #ffffff !important;
}
.smart-style-2 .pagination > li:last-child > a,
.smart-style-2 .pagination > li:last-child > span {
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  background-color: #ffffff !important;
}
.smart-style-2 table.pending-list-table {
  border: 1px solid #CCCCCC !important;
  margin-top: 0px !important;
  border-radius: 5px;
}
.smart-style-2 .table thead tr th {
  border-color: #ccc;
}
.smart-style-2 .table tbody tr td {
  border-color: #ccc;
}
.smart-style-2 .history-btn {
  color: #000;
}
.smart-style-2 .history-btn:hover {
  background-color: var(--theme-color-1) !important;
  color: var(--hovertext-color);
}
.smart-style-2 .darkHeaderIcon, .smart-style-2 .lightHeaderIcon {
  color: #000 !important;
}
.smart-style-2 .list-area {
  background-color: #0D0F12;
  border-radius: 0px;
}
.smart-style-2 .user_roles .c-btn {
  color: #ffffff;
  border-color: #424242;
  background: #616161;
}
.smart-style-2 .serviceTheme {
  background-color: #FFFFFF !important;
  border-radius: 0px;
}
.smart-style-2 .serviceTheme .ui-dialog-titlebar {
  background-color: #FFFFFF !important;
  border-bottom: none !important;
  width: 100% !important;
}
.smart-style-2 .serviceTheme .ui-dialog-titlebar .ui-dialog-title {
  width: 100% !important;
}
.smart-style-2 .serviceTheme .ui-dialog-titlebar .ui-dialog-title .widget-header {
  display: flex;
  justify-content: normal;
}
.smart-style-2 .serviceTheme .ui-dialog-titlebar .ui-dialog-title .shortDescription {
  text-align: center;
}
.smart-style-2 .serviceTheme .ui-dialog-titlebar .ui-dialog-titlebar-close {
  position: absolute !important;
  top: 25px;
  right: 5px;
}
.smart-style-2 .serviceTheme .ui-dialog-titlebar h5 {
  color: #000 !important;
}
.smart-style-2 .serviceTheme .ui-dialog-titlebar h1 {
  color: #000 !important;
}
.smart-style-2 .serviceTheme .ui-dialog-titlebar .ui-button {
  color: var(--theme-color-1);
  opacity: 1;
}
.smart-style-2 .serviceTheme .ui-dialog-content {
  background-color: #FFFFFF !important;
}
.smart-style-2 .serviceTheme .ui-dialog-content .service_modal {
  background-color: #FFFFFF !important;
}
.smart-style-2 .serviceTheme .ui-dialog-content .service_modal .modal-content {
  box-shadow: unset !important;
  -webkit-box-shadow: unset !important;
  border: unset !important;
  background-color: #FFFFFF !important;
}
.smart-style-2 .user-role-form .multiselect-dropdown .filter-textbox input {
  background: #ffffff !important;
}
.smart-style-2 .user-role-form .multiselect-dropdown .multiselect-item-checkbox {
  border-bottom: none !important;
}
.smart-style-2 .user-role-form .multiselect-dropdown .multiselect-item-checkbox input + div:before {
  color: #000 !important;
  border: 2px solid #000 !important;
}
.smart-style-2 .user-role-form .multiselect-dropdown .multiselect-item-checkbox input + div:focus {
  color: #000 !important;
  border: 2px solid #000 !important;
}
.smart-style-2 .user-role-form .multiselect-dropdown .multiselect-item-checkbox input + div:after {
  border-color: #000 !important;
}
.smart-style-2 .price_calcultation_form .multiselect-dropdown .filter-textbox input {
  background: #ffffff !important;
}
.smart-style-2 .price_calcultation_form .multiselect-dropdown .multiselect-item-checkbox {
  border-bottom: none !important;
}
.smart-style-2 .price_calcultation_form .multiselect-dropdown .multiselect-item-checkbox input + div:before {
  color: #000 !important;
  border: 2px solid #000 !important;
}
.smart-style-2 .price_calcultation_form .multiselect-dropdown .multiselect-item-checkbox input + div:focus {
  color: #000 !important;
  border: 2px solid #000 !important;
}
.smart-style-2 .price_calcultation_form .multiselect-dropdown .multiselect-item-checkbox input + div:after {
  border-color: #000 !important;
}
.smart-style-2 .reMark {
  color: #000;
  border: 1px solid #ced4da;
}
.smart-style-2 .darkHeaderIcon, .smart-style-2 .lightHeaderIcon, .smart-style-2 .editButtonsLabel {
  color: #000 !important;
}
.smart-style-2 .spin-loader {
  background: url('spin-loader_small.b2cbaf97b93dc78ca6c3.svg') no-repeat;
}
.smart-style-2 .big-spin-loader {
  background: url('spin-loader.a01fb811724c25c2dd1e.svg') no-repeat !important;
}
.smart-style-2 .errorOccured {
  border-color: #dc3545 !important;
}
.smart-style-2 .NextconfirmLabels {
  color: #000;
  border: 1px solid #ced4da;
}
.smart-style-2 .btnModalSave {
  float: right;
  background-color: #ffffff;
  border: none;
  color: #202124 !important;
  border-radius: 15px;
  border: 2px solid var(--theme-color-1);
}
.smart-style-2 .btnModalSave:hover {
  color: #ffffff !important;
}
.smart-style-2 .btnModalClose {
  float: left;
  background-color: #ffffff;
  border: none;
  color: #202124 !important;
  border-radius: 15px;
  border: 2px solid var(--theme-color-1) !important;
}
.smart-style-2 .successDiv {
  background-color: #F7F7F7 !important;
}
.smart-style-2 .successinviteDiv {
  background-color: #F7F7F7 !important;
}
.smart-style-2 .successappDiv {
  background-color: #F7F7F7 !important;
}
.smart-style-2 .successcmpimgDiv {
  background-color: #F7F7F7 !important;
}
.smart-style-2 .successcmpprofileimgDiv {
  background-color: #F7F7F7 !important;
}
.smart-style-2 .successuserdeleteDiv {
  background-color: #F7F7F7 !important;
}
.smart-style-2 .disabledColor {
  background-color: #F7F7F7 !important;
  color: #000 !important;
  border-color: #CCCCCC !important;
}
.smart-style-2 .cardimg {
  filter: brightness(100) invert(1);
}
.smart-style-2 .userInfromation, .smart-style-2 .seperator, .smart-style-2 .modal_header {
  color: #000 !important;
}
.smart-style-2 .inv-img {
  content: url('invoice.129e5b40d80804bcb275.svg') !important;
}
.smart-style-2 .badge-disable-iom {
  color: #111 !important;
}
.smart-style-2 .checkmark {
  border: 2px solid #212529;
}
.smart-style-2 .checkbox-container .checkmark:after {
  left: 7px;
  top: 1px;
  width: 8px;
  height: 16px;
  border: solid #000;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}
.smart-style-2 .checkcontractsmark {
  border: 2px solid #666666;
}
.smart-style-2 .checkbox-container .checkcontractsmark:after {
  left: 7px;
  top: 1px;
  width: 8px;
  height: 16px;
  border: solid #000;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}
.smart-style-2 .vertical-line {
  background-color: #CCCCCC;
}
.smart-style-2 .card_details_nocard p {
  color: #111;
  font-size: 14px;
}
.smart-style-2 .subPlanData {
  color: #111;
}
.smart-style-2 .table-wrapper {
  background-color: #EEEEEE 0% 0% no-repeat padding-box !important;
}
.smart-style-2 .table-wrapper table tbody tr td {
  background-color: #ffffff !important;
}
.smart-style-2 .tooltipRole:hover .tooltiptextRole {
  visibility: visible;
  border: 1px solid #CCCCCC;
  color: #000;
}
.smart-style-2 .tooltipRole:hover .tooltiptextRole:hover {
  background: #ffffff 0% 0% no-repeat padding-box !important;
}
.smart-style-2 .tooltipRole:hover .tooltiptextRole .insideDivUl li {
  border-bottom: 1px solid #CCCCCC;
}
.smart-style-2 .tooltipRole:hover .tooltiptextRole .insideDivUl li:last-child {
  border-bottom: unset;
}
.smart-style-2 .tooltipRole .tooltiptextRole {
  background-color: #ffffff;
  color: #000;
}
.smart-style-2 .header-formbtn {
  background-color: #ffffff !important;
  border: 2px solid var(--theme-color-1) !important;
  color: #000 !important;
  padding: 3px 15px;
}
.smart-style-2 .header-formbtn:hover {
  background: var(--theme-color-1) !important;
  color: #202528 !important;
}
.smart-style-2 .companyField,
.smart-style-2 .comapnyLbl, .smart-style-2 .manageHeader, .smart-style-2 .comapnyHeaderLbl {
  color: #000 !important;
}
.smart-style-2 .MessageBoxButtonSection button {
  color: #000 !important;
}
.smart-style-2 .subscriptions-page .table-bordered > thead > tr {
  background: #ffffff !important;
}
.smart-style-2 .subscriptions-page .table-bordered > thead > tr > th:not(:first-child) {
  border: 1px solid #CCCCCC;
}
.smart-style-2 .subscriptions-page table.noFeature.table-bordered > thead > tr {
  background: #ffffff !important;
}
.smart-style-2 .subscriptions-page table.noFeature.table-bordered > thead > tr > th {
  border: 1px solid #CCCCCC;
}
.smart-style-2 .top-div {
  border-bottom: 1px solid #CCCCCC !important;
}
.smart-style-2 .top-div .description-div .description-content {
  border: 1px solid #CCCCCC !important;
}
.smart-style-2 .step-div {
  background: #ffffff;
}
.smart-style-2 .footer-div {
  background: #ffffff;
}
.smart-style-2 .algntblTranscation {
  border-radius: 20px;
  border: 1px solid #CCCCCC !important;
  overflow: hidden;
  background: #ffffff !important;
}
.smart-style-2 .setselectHeight {
  border: 1px solid #BDBDBD;
  background-color: #ffffff;
  color: #202528 !important;
}
.smart-style-2 .successdeleteDiv {
  background-color: #F7F7F7 !important;
}
.smart-style-2 .btnModalClose {
  float: left;
  background-color: #ffffff;
  border: none;
  border-radius: 15px;
  border: 2px solid var(--theme-color-1) !important;
}
.smart-style-2 .ui-datepicker td .ui-state-default {
  color: #707070;
}
.smart-style-2 .ui-datepicker .ui-datepicker-header {
  background-color: #ffffff;
}
.smart-style-2 .ui-datepicker td .ui-state-highlight {
  color: #ffffff;
}
.smart-style-2 .ui-datepicker td .ui-state-hover {
  color: #ffffff;
}
.smart-style-2 .iconForm {
  color: #000 !important;
}
.smart-style-2 #extr-page {
  background: #ffffff !important;
}
.smart-style-2 .border-style {
  border-color: #CCCCCC !important;
}
.smart-style-2 .e-div-2 {
  border-bottom: 1px solid #ced4da;
}
.backdrop {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  background: black;
}
#overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  bottom: 0;
  z-index: 999;
}
.search_status {
  color: #202124;
  background-color: unset !important;
}
.drop {
  color: #000 !important;
}
.nav_btn {
  color: #fff !important;
}
.nav_btn:hover {
  color: var(--hovertext-color) !important;
}
.commoninputStyle .form-control {
  height: 40px !important;
  border-radius: 6px !important;
  background-color: #fff;
  border: 1px solid #CCCCCC;
  font-size: 14px;
}
/*
---------------------------------------------------------------------------------------
    End White theme style
---------------------------------------------------------------------------------------
*/
.owl-dt-calendar-table.owl-dt-calendar-month-table .owl-dt-day-6, .owl-dt-calendar-table.owl-dt-calendar-month-table .owl-dt-day-0 {
  cursor: default;
  pointer-events: none;
}
.owl-dt-calendar-table.owl-dt-calendar-month-table .owl-dt-day-6 .owl-dt-calendar-cell-content, .owl-dt-calendar-table.owl-dt-calendar-month-table .owl-dt-day-0 .owl-dt-calendar-cell-content {
  color: rgba(0, 0, 0, 0.4);
}
.ediPriceCalc ng-multiselect-dropdown .multiselect-dropdown .dropdown-list {
  position: fixed;
  width: auto;
}
.ediPriceCalc ng-multiselect-dropdown .multiselect-dropdown .dropdown-list[hidden] {
  position: absolute;
}
#header.colorChangeTest {
  background-color: var(--header-color) !important;
}
.englishText {
  font-size: 10px !important;
  padding: 2px 5px !important;
  width: 16% !important;
}
.germanText {
  font-size: 8px !important;
  padding: 3px 5px !important;
  width: 17% !important;
}
.englishTextFuture {
  width: 16% !important;
}
.germanTextFuture {
  width: 17% !important;
}
@media (min-width: 1440px) and (max-width: 1800px) {
  .englishText {
    font-size: 8px !important;
  }

  .germanText {
    font-size: 6px !important;
  }

  .englishTextFuture {
    font-size: 10px !important;
  }

  .germanTextFuture {
    font-size: 10px !important;
  }
}
@media (max-width: 1440px) {
  .englishText, .englishTextFuture, .germanTextFuture, .germanText {
    width: 16% !important;
  }
}
.cookie-consent {
  width: 100%;
  flex-wrap: wrap;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  color: #d6d6d6;
  background-color: #3c404d;
  padding: 0.6em 1.8em;
  z-index: 9999;
  padding: 10px 0 0 10px;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.consent-btn {
  color: #000;
  flex: 1 0 130px;
  background-color: var(--theme-color-1);
  border-color: transparent;
  padding: 0.2em 0.5em;
  font-size: 1.2em;
  font-family: Helvetica, Calibri, Arial, sans-serif;
  font-weight: bolder;
  border-width: 2px;
  border-style: solid;
  text-align: center;
  white-space: nowrap;
  border-radius: 5px;
  min-height: 20px;
  margin: 0 30px 10px 10px;
  max-width: 100px;
}
.cookie-text {
  font-size: 12px;
}
a.cookie_anchor {
  color: var(--theme-color-1);
  text-decoration: none;
  font-weight: 500;
}
a.cookie_anchor:hover {
  color: var(--theme-color-1);
}
table thead tr th {
  font-size: 14px;
  font-weight: 400;
}
.smart-style-1 .btnModalSave {
  float: right;
  background-color: #202124;
  border: none;
  color: #ffffff !important;
  border-radius: 15px;
  border: 2px solid var(--theme-color-1);
}
.smart-style-2 .btnModalSave {
  float: right;
  background-color: #ffffff;
  border: none;
  color: #202124 !important;
  border-radius: 15px;
  border: 2px solid var(--theme-color-1);
}
.smart-style-1 .positionTable {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  border: 1px solid #333333 !important;
  border-radius: 5px !important;
}
.smart-style-2 .positionTable {
  border: 1px solid #cccccc !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  border-radius: 5px !important;
}
.jarviswidget {
  position: static !important;
}
.infoIcon {
  filter: var(--filter);
}
.credInfoIcon {
  filter: var(--filter);
}
.epost .epostlogoBackGround {
  background-repeat: no-repeat;
  background-size: 100%;
  height: 140px !important;
  width: 62px !important;
  display: block;
  margin-top: 1px !important;
  margin-left: 82px !important;
}
.epost .green-label {
  color: var(--theme-color-1) !important;
}
.epost .bg-color-red {
  background-color: var(--theme-color-1) !important;
}
