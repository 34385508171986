/* You can add global styles to this file, and also import other style files */
/* #GOOGLE FONT */
@import "commonstyle";
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400italic,700italic,300,400,700");
@import "~@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";
body:not(.menu-on-top).desktop-detected {
    min-height: auto !important;
	overflow: hidden;
}
body:not(.menu-on-top).mobile-detected {
    min-height: auto !important;
	overflow: hidden;
}
body {
    background: unset;
    background-color: #202124;
}
#content{
	padding: 20px !important;
	max-height: calc(100vh - 100px);
    overflow-y: auto;
}
.page-footer {
    /* padding-left: 0px; */
    z-index: 0;
	border-top: none;
}
#logo {    
    background-repeat: no-repeat;
    background-size: 100%;
    height: 49px;
    width: 135px;
    display: block;
    margin-top: 13px;
	margin-left: 36px;
}
.xatena #logo { 
	margin-top: 6px !important;
}
.setnavBackgroundImg{	
    position: relative;
    z-index: 2;
    max-width: none;
    width: 90px;
    height: 90px;
    cursor: pointer;
	background-repeat: no-repeat;
	display: flex;
	margin: 0 auto;
	width: 110px;
	height: 110px;
}
.setBackgroundCompanyImg{
	position: relative;
    z-index: 2;
    max-width: none;
    width: 200px;
    height: 200px;
    cursor: pointer;
	background-repeat: no-repeat;
	display: flex;
	margin: 0 auto;
}
.setBackgroundCompanylogoImg{
	position: relative;
    z-index: 2;
    max-width: none;
    width: 200px;
    height: 200px;
    cursor: pointer;
	background-repeat: no-repeat;
	display: flex;	
}
.col-xs-10 {
    width: 83.33333333% !important;
}

.col-xs-2 {
    width: 16.66666667% !important;
}

.col-xs-3 {
    width: 25% !important;
}

.col-xs-7 {
    width: 58.33333333% !important;
}

.col-xs-8 {
    width: 66.66666667% !important;
}

.col-xs-6 {
    width: 50% !important;
}

.col-xs-1 {
    width: 8.33333333% !important;
}

.col-xs-4 {
    width: 33.33333333% !important;
}

.col-xs-5 {
    width: 41.66666667% !important;
}

#extr-page {
	overflow-x: hidden;
	#header {
		#logo {
			img {
				width: 200px;
			}
			margin-left: 75px !important;
			margin-top: 10px !important;
		}
	}
}

.notification-body {
	// .unread {
	// 	background: $table-bgcolor !important;
	// }
	li {
		span {
			background: $secondaryFocus !important;
		}
	}
}
.li-footer__copy-text {
	color: #8B91A0;
}
.loader-outer{
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -25px 0 0 -25px;
  }
  .loader>span {
    width: 15px;
    height: 15px;
    display: block;
    background: $common_base_color;
    border-radius: 50%;
    position: relative;
    margin: 0 5px;
  }
  
  .loader {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .dot-1 {
    animation: anim 1s linear 0s infinite;
  }
  
  .dot-2 {
    animation: anim 1s linear 0.25s infinite;
  }
  
  .dot-3 {
    animation: anim 1s linear 0.50s infinite;
  }
  
  .dot-4 {
    animation: anim 1s linear 0.75s infinite;
  }
  
  @keyframes anim {
    0% {
      top: 0;
    }
  
    50% {
      top: 15px;
    }
  
    100% {
      top: 0;
    }
  }
@media (max-width: 1161px) and (min-width: 300px) {
    #extr-page #header #logo img {
        width: 150px;
    }
    #extr-page #header #logo {
        margin-left: 10px !important;
        margin-top: 20px !important;
    }
}

@media only screen and (max-width: 280px) {
    #header>:first-child,
    aside {
        width: 150px;
    }
    #extr-page #header #logo img {
        width: 125px;
    }
    #extr-page #header #logo {
        margin-left: 10px !important;
        margin-top: 20px !important;
    }
}
.checkbox i{
	position: absolute;
	top: 3px;
	left: 0;
	display: block;
	width: 17px;
	height: 17px;
	outline: 0;
	border-width: 1px;
	border-style: solid;
	background: transparent !important;
	border: 2px solid #666666;
	border-radius: 5px;
}


.smart-form .checkbox input+i:after {
	color: $common_base_color;
}
.smart-form .checkbox i {
	border-color: #BDBDBD;
	border: 2px solid #666666;
	border-radius: 5px;
}
.smart-form .checkbox:hover i{
	border-color: #666666;
}
.smart-form .checkbox input:checked+i, .smart-form .radio input:checked+i, .smart-form .toggle input:checked+i {
	border-color: #666666;
          }
/*
---------------------------------------------------------------------------------------
    Default Dark theme style
---------------------------------------------------------------------------------------
*/
.smart-style-1 {
	#logo-group {
		background: unset !important;
	}
	/* #logo {
		background-image: url(/assets/img/iomLogoWhite.svg);
	} */
	.btn-header {

			&:first-child {
				a {
					background: unset;
					border: 1px solid $border-color;
				}
		}
	}
	#header {
		background-color: #202124;
	}
	aside {
		background-color: #171819;
		border-right: 1px solid #333 !important;
	}
	.page-footer {
		background-color: $common-color;
	}
	#ribbon {
		background: $tabsborder-color;
	}
	.ajax-dropdown {
		background-color: $secondaryFocus !important;
	}
	.ajax-notifications {
		background-color: $secondaryFocus !important;
	}
	.header-dropdown-list {
		a {
			&:hover {
				color: $primary-color !important;
			}
		}
	}
	.setnavBackgroundImg{
		background-image: url(/assets/img/svgImages/Group348.svg);	
	}
	.setcompanyImg{
		background-image: url(/assets/img/svgImages/comapnyDefaultDark.svg)
	}
	.setBackgroundCompanyImg,
	.setBackgroundCompanylogoImg{
		background-image: url(/assets/img/svgImages/comapnyDefaultDark.svg)
	}
	.owl-dt-control-button-arrow{
		fill: $primary-color !important;
	}
}

/*
---------------------------------------------------------------------------------------
    End Dark theme style
---------------------------------------------------------------------------------------
*/

/*
---------------------------------------------------------------------------------------
    Default Dark theme style
---------------------------------------------------------------------------------------
*/
.smart-style-2 {
	.company-label{
		color:#4c4f53 !important;
	}
	#logo {
		background-image: url(/assets/img/iomLogoBlack.svg);
		margin-top: 14px;
	}
	.login-info {
		color: #212529;
	}
	aside{
		color: #ffffff !important;
		// background: #171819 !important;
		background-image: #ededed !important;
	 }
	 #header {
		background-color: $primary-color !important;
	}
	nav ul .active>a{
		color: $primary-color !important;
                            font-weight: 400 !important;
		font-size: 16px !important;
	}
	.setnavBackgroundImg{
		background-image: url(/assets/img/svgImages/Group349.svg);	
	}
	.setBackgroundCompanyImg,
	.setBackgroundCompanylogoImg{
		background-image: url(/assets/img/svgImages/Group350.svg);	
	}
	.smart-style-2 .smart-form .checkbox input:checked+i, .smart-style-2 .smart-form .radio input:checked+i, .smart-style-2 .smart-form .toggle input:checked+i {
		border-color: #666666;
	          }
	
}

/*
---------------------------------------------------------------------------------------
    White Dark theme style
---------------------------------------------------------------------------------------
*/

// @media (min-width: 576px){
// 	#content{
// 		max-height: calc(90vh - 100px) !important;
// 	}	
// }

// @media (min-width: 768px){
// 	#content{
// 		max-height: calc(120vh - 100px) !important;
// 	}
// }

// @media (max-width: 992px){}

// @media (max-width: 1250px){}

// @media (min-width: 1250px) and (max-width: 1500px){

// }
.smart-style-2.minified #left-panel {
	padding: 0!important;
	padding-top: 10px !important;
          }
@media (max-width: 992px) {
	#content{
		max-height: calc(100vh - 130px) !important;
	}
}
@media (max-width: 576px){
	#content{
		max-height: calc(100vh - 180px) !important;
	}
}

// owl-datetime picker

.cdk-overlay-container {
	z-index: 9999 !important;
}

.owl-dt-inline {
	width: 100% !important;
}

.owl-dt-inline-container,
.owl-dt-popup-container {
	width: 100% !important;
}
.owl-dt-container{
	font-size: 18px !important;
}
.owl-dt-inline-container .owl-dt-calendar,
.owl-dt-popup-container .owl-dt-calendar{
	height: 500px !important;
}

.cdk-overlay-container {
	z-index: 9999 !important;
}

.owl-dt-inline {
	width: 100% !important;
}

.owl-dt-inline-container,
.owl-dt-popup-container {
	width: 100% !important;
}

.owl-dt-container {
	font-size: 18px !important;
}

.owl-dt-inline-container .owl-dt-calendar,
.owl-dt-popup-container .owl-dt-calendar {
	height: 500px !important;
}

.owl-dt-calendar-table .owl-dt-calendar-cell-active:focus>.owl-dt-calendar-cell-content:not(.owl-dt-calendar-cell-selected),
.owl-dt-calendar-table :not(.owl-dt-calendar-cell-disabled):hover>.owl-dt-calendar-cell-content:not(.owl-dt-calendar-cell-selected) {
	background-color: #666666 !important;
	color: #fff !important;
}

.owl-dt-calendar-table .owl-dt-calendar-cell-today:not(.owl-dt-calendar-cell-selected){
	background-color: #202124 !important;
	border: 1px solid #666666 !important;
	color: #fff !important;
}