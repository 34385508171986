:root {
    --theme-color-1: #8b9635;
    --header-color:rgba(183, 235, 0, 0.4);
    --hovertext-color: #ffffff;
    --text-color: #111;
    --filter: brightness(0) saturate(100%)
    }
$primary-color: #ffffff;
$base-color:#E2E2E2;
$tablehover-color:#073E84;
$invalid-color:#eb0042 ;
$border-color:#e4e4e4;
$table-bgcolor:#1E1E1E;
$subborder-color:#3B424C;
$label-color:#868686;
$background_color:#3276b1;
$secondaryFocus:#111;
$fontColor:#dadce0;
$btndanger-color:#DC4B38;
$box-border:#5E5E5E;
$panel-color:#8B91A0;
$subtext-color:#7A7A7A;
$fontbackground-color:#EEE;
$btncolor:#198754;
$hover-color:#886ab5;
$hover-bgcolor:#a38cc6;
$secondary-color:#202528;
$activetab-color:#707070;
$common-color:#000;
$navigation-border:#262626;
$secondaryFocus:#0C1214;
$tabsborder-color:#707070;
$card-color:#959595;
$search-border:#3B424C;
$widget-bgcolor:#171717;
$header-border: #3F3E3E;
$commonbase-color:var(--theme-color-1);
$content-bg-color:#202124;
$card-bordercolor:#3B424C;
$subscribe-bgcolor:#073E84;
$payment-color:#005397;
$formcolor:#7991b4;
$tabsborder:#ddd;
$darktheme-bgcolor:#171717;
$header-bgcolor:#333;
$common_base_color:var(--theme-color-1);
$invalid-bordercolor:#dc3545;
$darkTextColor: #111;
$baseborder-color:#333;
$buttondanger-color:#dc3545;
$header-color:var(--header-color);
$light-nav-font-color:#4c4f53;
$hovertext-color:var(--hovertext-color);
$text-color: var(--text-color);
$filter: var(--filter);